import { Result, Tabs } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TabTitle } from '../../components/TabTitle';
import { CertificationBinTab } from '../../components/bin/CertificationBinTab';
import { CollectionBinTab } from '../../components/bin/CollectionBinTab';
import { FabricBinTab } from '../../components/bin/FabricBinTab';
import { FabricLibraryBinTab } from '../../components/bin/FabricLibraryBinTab';
import { InspirationBinTab } from '../../components/bin/InspirationBinTab';
import { ProjectBinTab } from '../../components/bin/ProjectBinTab';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { MainLayout } from '../../layouts/Main';
import { BinContentsType } from '../../utils/backendRequests/bin/BinTypes';
import { getBinList } from '../../utils/backendRequests/bin/getBinList';
import { RootAuth } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function BinListPage({ title }: TitleProp) {
  const [binContents, setBinContents] = useState<BinContentsType>({
    libraries: [],
    collections: [],
    fabrics: [],
    projects: [],
    certifications: [],
    user_uploads: [],
  });
  const [activeKey, setActiveKey] = useState<string>('');
  const [isBinEmpty, setIsBinEmpty] = useState(false);
  const role = useSelector((state: RootAuth) => state.auth?.role);

  // Gets the binned list
  const getBinContents = async () => {
    setBinContents(await getBinList());
  };

  // Initialise the page with binned list
  useEffect(() => {
    getBinContents();
  }, []);

  // Sets the default opened tab depending which tab has resources to delete.
  // If empty don't show any tab and set isBinEmpty to true.
  useEffect(() => {
    const keysArray = Object.keys(binContents) as (keyof BinContentsType)[];

    if (
      binContents[keysArray[parseInt(activeKey)]]?.length === 0 ||
      activeKey === ''
    ) {
      const activeKeyIndex = keysArray.findIndex(
        (key) => binContents[key].length > 0
      );

      const activeKeyTemp =
        activeKeyIndex !== -1 ? activeKeyIndex.toString() : '';

      setIsBinEmpty(activeKeyTemp === '');

      if (activeKey !== activeKeyTemp) {
        setActiveKey(activeKeyTemp);
      }
    }
  }, [binContents, activeKey]);

  const allBinItems = [
    {
      label: i18n.t('headers:libraries'),
      permission: ['supplier'],
      children: (
        <FabricLibraryBinTab
          fabricLibraries={binContents.libraries}
          getBinContents={getBinContents}
        />
      ),
      disabled: binContents.libraries.length === 0,
    },
    {
      label: i18n.t('headers:collections'),
      permission: ['supplier'],
      children: (
        <CollectionBinTab
          collections={binContents.collections}
          getBinContents={getBinContents}
        />
      ),
      disabled: binContents.collections.length === 0,
    },
    {
      label: i18n.t('headers:fabrics'),
      permission: ['supplier'],
      children: (
        <FabricBinTab
          fabrics={binContents.fabrics}
          getBinContents={getBinContents}
        />
      ),
      disabled: binContents.fabrics.length === 0,
    },
    {
      label: i18n.t('headers:projects'),
      permission: ['designer'],
      children: (
        <ProjectBinTab
          projects={binContents.projects}
          getBinContents={getBinContents}
        />
      ),
      disabled: binContents.projects.length === 0,
    },
    {
      label: i18n.t('headers:certifications'),
      permission: ['supplier'],
      children: (
        <CertificationBinTab
          certifications={binContents.certifications}
          getBinContents={getBinContents}
        />
      ),
      disabled: binContents.certifications.length === 0,
    },
    {
      label: i18n.t('headers:inspirations'),
      permission: ['supplier', 'designer'],
      children: (
        <InspirationBinTab
          inspirations={binContents.user_uploads}
          getInspirations={getBinContents}
        />
      ),
      disabled: binContents.user_uploads.length === 0,
    },
  ].map((item, index) => {
    // Keys should have a string number in sequence starting from '0'
    return { ...item, key: index.toString() };
  });

  // Bin items suppliers have access to, which is all.
  const supplierBinItems = allBinItems.filter((item) => {
    return item.permission.includes('supplier');
  });

  // Bin items designers have access to, which is projects, inspirations and certifications
  const designerBinItems = allBinItems.filter((item) => {
    return item.permission.includes('designer');
  });

  return (
    <MainLayout>
      <HeaderTitle title={i18n.t('page_titles:bin_page')} />
      <TabTitle title={title} />
      <Tabs
        activeKey={activeKey}
        items={role === 'supplier' ? supplierBinItems : designerBinItems}
        onChange={setActiveKey}
      />
      {isBinEmpty && (
        <Result
          title={i18n.t('headers:bin_is_empty')}
          subTitle={i18n.t('headers:nothing_to_delete')}
        />
      )}
    </MainLayout>
  );
}
