import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { RootAuth } from '../../../utils/types/Types';
import { TabTitle } from '../../TabTitle';
import { ColoursListProps, columnFields } from '../TableTypes';
import { coloursColumns } from './ColoursColumns';

export function ColourList({
  data,
  title,
  editColourOnClick,
}: ColoursListProps) {
  const [columns, setColumns] = useState<columnFields[]>([]);
  const brandName = useSelector((state: RootAuth) => state.auth?.brandName);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  useEffect(() => {
    setColumns(
      coloursColumns(editColourOnClick, brandName, account) as columnFields[]
    );
  }, [editColourOnClick]);

  return (
    <>
      {data[0] && <TabTitle title={title} />}
      <TableGeneric columns={columns} data={data} isBackendPaginated={false} />
    </>
  );
}
