import { useEffect, useState } from 'react';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { editFabrics } from '../../../utils/backendRequests/admin/editFabrics';
import { record } from '../../../utils/types/Types';
import { AdminUpdateTrainingLabelModal } from '../../modal/AdminUpdateTrainingLabelModal';
import { TabTitle } from '../../TabTitle';
import { AdminFabricListProps, FabricColumn } from '../TableTypes';
import { fabricColumns } from './fabricColumns';

/**
 * Display table of lace fabrics allowing the admin to update
 * the machine learning training labels for selected fabrics.
 */
export function AdminFabricList({
  data,
  title,
  isModalOpen,
  setIsModalOpen,
  setData,
}: AdminFabricListProps) {
  const [columns, setColumns] = useState<FabricColumn[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const handleSubmitEditableLabel = async (
    label: string | null,
    fabricIds: string[]
  ) => {
    const updatedFabrics = await editFabrics({
      fabricIds: fabricIds,
      label: label,
    });
    if (updatedFabrics) {
      setData((prevData) =>
        prevData.map((fabric) => {
          if (fabricIds.includes(fabric.id)) {
            return { ...fabric, ml_training_label: label };
          }
          return fabric;
        })
      ); // update the data in the table to reflect the changes without needing to refresh the page
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setColumns(fabricColumns(handleSubmitEditableLabel));
  }, []);

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange as (
      selectedRowKeys: React.Key[],
      selectedRows: record[]
    ) => void,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <AdminUpdateTrainingLabelModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        hasSelected={hasSelected}
        selectedRowKeys={selectedRowKeys}
        data={data}
        setData={setData}
      />
      {/* if no data's fed in, show parent component's title for browser tab, i.e. use 'Fabric' instead of 'Library :id', otherwise child will overide parent tab's title */}
      {data[0] && <TabTitle title={title} />}
      <TableGeneric
        columns={columns}
        data={data}
        // @ts-expect-error because not a record for generic table with row selection
        rowSelection={rowSelection}
        isBackendPaginated={false}
      />
    </>
  );
}
