import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Remove approved supplier brand via their id
 */
export async function deleteActiveSupplier(
  supplierId: string
): Promise<boolean> {
  try {
    await api({
      method: 'DELETE',
      url: `v1/brand/supplier/${supplierId}`,
    });
    message.success(
      i18n.t('long_messages:message_supplier_brand_removed_success')
    );
    return true;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response?.data as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
}
