import { Button, Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { UpdateBrandImage } from '../../../elements/modals/StyledModal';
import { FormWrapper } from '../../../elements/wrapper/FormWrapper';
import i18n from '../../../plugins/i18n';
import { editBrandLogo } from '../../../utils/backendRequests/admin/editBrandLogo';
import { editSupplierBrand } from '../../../utils/backendRequests/admin/editSupplierBrand';
import { nullParser } from '../../../utils/parsers/parseNullValues';
import { Modal } from '../../Modal';
import { FormItems } from '../../form/FormItems';
import { DropdownOption, SetPropsWithDropDownObj } from '../../form/FormTypes';
import { SupplierBrandValues, SupplierRecord } from '../../table/TableTypes';
import { ImageUpload } from '../../upload/ImageUpload';
import { commonBrandFields } from './brandFormFields';
import { brandFieldRules } from './brandFormRules';

interface UpdateSupplierBrandProps {
  modal: { visible: boolean };
  setModal: (modal: { visible: boolean }) => void;
  brand: SupplierRecord;
  supplierBrandId: string;
  getBrands: () => void;
}

type dropDownDataType = {
  country_id: {
    value: number;
    label: string;
  }[];
};

export function UpdateSupplierBrandModal({
  modal,
  setModal,
  brand,
  supplierBrandId,
  getBrands,
}: UpdateSupplierBrandProps) {
  const [form] = Form.useForm();
  const [logoImg, setLogoImg] = useState<File | null>(null);
  const [logoImgUrl, setLogoImgUrl] = useState<string | null>(null);
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState<
    dropDownDataType | Record<string, never>
  >({});

  const fabricRulesConst = useMemo(
    () => brandFieldRules(commonBrandFields),
    []
  );
  const uploadLogo = (
    <ImageUpload
      fieldName={i18n.t('header:logo')}
      setImage={setLogoImg}
      imageUrl={logoImgUrl}
      setImageUrl={setLogoImgUrl}
    />
  );

  //TODO: UseEffect BUG Where on submit reverts to old values
  useEffect(() => {
    if (brand && Object.keys(propsWithDropDownObj).length > 0) {
      //TODO: This logic needs to be removed in future where
      //return ID not string from backend
      const findMatchingOption = (options: DropdownOption[], label: string) =>
        options?.find((option) => option.label === label)?.value ?? null;

      // Prepare updated brand object without mutating the original
      const updatedBrand = {
        ...brand,
        country_id: findMatchingOption(
          (propsWithDropDownObj as dropDownDataType)?.country_id,
          brand.country ?? ''
        ),
      };
      form.setFieldsValue(updatedBrand);
    }
    setLogoImgUrl(brand?.logo_image_url);
  }, [brand, form, propsWithDropDownObj]);

  // Close modal
  const handleCancel = () => {
    setModal({ visible: false });
  };

  const handleSubmit = async (values: SupplierBrandValues) => {
    const parsedBrands = nullParser(values) as SupplierBrandValues;
    let isBrandEdited = false;
    let isLogoEdited = false;

    if (await editSupplierBrand(parsedBrands, supplierBrandId)) {
      form.resetFields();
      setModal({ visible: false });
      isBrandEdited = true;
    }
    if (logoImg) {
      const formData: FormData = new FormData();
      formData.append('logo_image', logoImg);
      if (await editBrandLogo(formData, supplierBrandId, 'supplier')) {
        setLogoImg(null);
        setLogoImgUrl(null);
        isLogoEdited = true;
      }
    }
    if (isBrandEdited || isLogoEdited) {
      getBrands();
    }
  };

  return (
    <Modal
      title={`Update Supplier Brand`}
      open={modal.visible}
      onCancel={handleCancel}
      footer={null}
    >
      <UpdateBrandImage direction="vertical" size="large">
        {uploadLogo}
      </UpdateBrandImage>
      <FormWrapper>
        <Form form={form} onFinish={handleSubmit}>
          <FormItems
            formFields={commonBrandFields}
            propsWithDropDownObj={propsWithDropDownObj}
            setPropsWithDropDownObj={
              setPropsWithDropDownObj as SetPropsWithDropDownObj
            }
            formRules={fabricRulesConst}
          />
          <Form.Item wrapperCol={{ offset: 20, span: 12 }}>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  );
}
