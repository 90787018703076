import { Input } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { updateProjectDetails } from '../../../utils/backendRequests/project/updateProjectDetails';
import { stringValid } from '../../../utils/validations/stringValidator';
import { Modal } from '../../Modal';
import { EditProjectModalWithLinkProps } from '../TableTypes';

export function EditProjectModalWithLink({
  record,
  getProjectList,
}: EditProjectModalWithLinkProps) {
  const [modal, setModal] = useState({ visible: false });
  const { name, description, id } = record;
  const [newName, setNewName] = useState(name);
  const [errorName, setErrorName] = useState(false);
  const [newDesc, setNewDesc] = useState<string | null>(description);

  const handleCancel = () => {
    setModal({ visible: false });
  };

  // Reset newName and errorName when name changes
  useEffect(() => {
    setNewName(name);
    setNewDesc(description);
    setErrorName(false);
  }, [name, description]);

  const handleOK = async () => {
    setErrorName(false);
    const isNameValid = stringValid(newName, 50, i18n.t('common:name'), false);
    const isDescriptionValid = stringValid(
      newDesc,
      500,
      i18n.t('column_titles:description'),
      true
    );
    if (isNameValid && isDescriptionValid) {
      const descriptionValue = newDesc === '' ? null : newDesc;
      const successFlag = await updateProjectDetails(
        id,
        newName,
        descriptionValue
      );
      if (successFlag) {
        getProjectList();
        setModal({ visible: false });
      } else {
        setErrorName(true);
        setModal({ visible: true });
      }
    } else {
      setErrorName(true);
    }
  };

  const showModal = () => {
    setModal({ visible: true });
  };

  return (
    <>
      <Link onClick={showModal} to={''}>
        {i18n.t('buttons:edit')}
      </Link>
      <Modal
        title={i18n.t('headers:edit_project')}
        open={modal.visible}
        onCancel={handleCancel}
        onOk={handleOK}
      >
        {`${i18n.t('common:name')}:`}
        <Input
          allowClear
          onChange={(evt) => setNewName(evt.target.value)}
          value={newName}
          status={errorName ? 'error' : ''}
          style={{ marginBottom: '10px' }}
        />
        {`${i18n.t('column_titles:description')}:`}
        <Input
          showCount
          value={newDesc !== null ? newDesc : ''}
          style={{ marginTop: '10px' }}
          onChange={(evt) => setNewDesc(evt.target.value)}
        />
      </Modal>
    </>
  );
}
