import { Button, Checkbox, Col } from 'antd';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  MobileSelectableFieldContainer,
  SelectableMobileContainer,
} from '../../elements/modals/StyledModal';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../utils/fabrics/parseFabricFields';
import {
  getSelectedLabelsFromLocalStorage,
  setSelectedLabelsInStorage,
} from '../../utils/fabrics/selectedFabricTableColumnsLabels';
import { RootAuth } from '../../utils/types/Types';
import { formFieldsProp } from '../form/FormTypes';
import { Modal } from '../Modal';
import { selectableColumns as columns } from '../table/fabricTable/ColumnsDesktop';
import {
  getSelectedMobileColumns,
  selectableDefaultColumnsLabels,
} from '../table/fabricTable/ColumnsMobile';
import { columnFields } from '../table/TableTypes';

interface SelectMobileColumsModalProps {
  modal: {
    visible: boolean;
  };
  setModal: (modal: { visible: boolean }) => void;
  setSelectedCol: (columns: columnFields[]) => void;
  pageType: string;
  selectableColumnNames: string[];
}

export function SelectMobileFabricColumns({
  modal,
  setModal,
  setSelectedCol,
  pageType,
  selectableColumnNames,
}: SelectMobileColumsModalProps) {
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const [selectedLabels, setSelectedLabels] = useState(
    getSelectedLabelsFromLocalStorage(true)
  );
  const [selectAllMode, setSelectAllMode] = useState(true);
  const [filteredColumns, setFilteredColumns] = useState<formFieldsProp[]>([]);

  useEffect(() => {
    const selectedColumns = columns.filter((column) =>
      selectableColumnNames.includes(column.name)
    );
    const filteredColumnsWithDefaults = [
      ...returnOrderedFields(selectableDefaultColumnsLabels),
      ...selectedColumns,
    ];
    setFilteredColumns(filteredColumnsWithDefaults);
  }, [selectableColumnNames]);

  const handleUpdateColumn = (selectColumn: string) => {
    const updatedColumns = selectedLabels.includes(selectColumn)
      ? selectedLabels.filter(
          (selectedColumn) => selectedColumn !== selectColumn
        )
      : [...selectedLabels, selectColumn];
    setSelectedLabels(updatedColumns);
  };

  const handleDefault = () => {
    setSelectedLabels(selectableDefaultColumnsLabels);
  };

  const handleCancel = () => {
    const storedLabels = getSelectedLabelsFromLocalStorage(true);
    setSelectedLabels(storedLabels);
    setModal({ visible: false });
  };

  const handleOk = () => {
    setSelectedLabelsInStorage(selectedLabels, true);
    const columns = getSelectedMobileColumns(
      pageType,
      measurementUnit,
      selectableColumnNames,
      role,
      account
    );
    setSelectedCol(columns);
    setModal({ visible: false });
  };

  const handleToggleAll = () => {
    if (selectAllMode) {
      setSelectedLabels(filteredColumns.map((col) => col.name));
    } else {
      setSelectedLabels([]);
    }
  };

  useEffect(() => {
    setSelectAllMode(selectedLabels.length !== filteredColumns.length);
  }, [selectedLabels]);

  // Group columns by "grouping" type
  const groupColumnsByType = (columns: formFieldsProp[]) => {
    return columns.reduce(
      (acc, column) => {
        const group = column.grouping as string;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(column);
        return acc;
      },
      {} as Record<string, formFieldsProp[]>
    );
  };

  const groupedColumns = groupColumnsByType(filteredColumns);

  const DefaultSelectedColumns = ['name', 'image_url_front'];

  const renderedGroups = () => (
    <>
      {Object.entries(groupedColumns).map(([group, cols]) => (
        <Col key={group}>
          <h4 style={{ margin: '0 0 0px 0' }}>{i18n.t(`headers:${group}`)}</h4>
          {group === 'basic' &&
            DefaultSelectedColumns.map((col) => (
              <MobileSelectableFieldContainer key={col}>
                <Checkbox checked disabled />
                <span style={{ marginLeft: '8px' }}>
                  {parseFabricTitles(col, measurementUnit)}
                </span>
              </MobileSelectableFieldContainer>
            ))}
          {cols.map((col) => (
            <MobileSelectableFieldContainer key={col.name}>
              <Checkbox
                checked={selectedLabels.includes(col.name)}
                onChange={() => handleUpdateColumn(col.name)}
              />
              <span style={{ marginLeft: '8px' }}>
                {parseFabricTitles(col.name, measurementUnit)}
              </span>
            </MobileSelectableFieldContainer>
          ))}
        </Col>
      ))}
    </>
  );

  return (
    <Modal
      title={i18n.t('headers:select_fabric_columns')}
      width="600px"
      open={modal.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <React.Fragment key="footer">
          <Button onClick={handleToggleAll}>
            {selectAllMode
              ? i18n.t('buttons:select_all')
              : i18n.t('buttons:deselect_all')}
          </Button>
          <Button onClick={() => handleDefault()}>
            {i18n.t('buttons:default')}
          </Button>
          <Button danger onClick={() => handleCancel()}>
            {i18n.t('buttons:cancel')}
          </Button>
          <Button type="primary" onClick={() => handleOk()}>
            {i18n.t('buttons:ok')}
          </Button>
        </React.Fragment>,
      ]}
    >
      <SelectableMobileContainer>{renderedGroups()}</SelectableMobileContainer>
    </Modal>
  );
}
