import { Input, Modal } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';
import { createChat } from '../../../utils/backendRequests/hangers/createChat';
import { createHangerRequest } from '../../../utils/backendRequests/hangers/createHangerRequest';
import { CreateHangerMessage } from '../../table/hangersList/HangerTypes';

const { TextArea } = Input;

interface CreateHangerRequestModalProps {
  visible: boolean;
  onCancel: () => void;
  fabricIds: string[];
}

export function CreateFabricsHangerRequest({
  visible,
  onCancel,
  fabricIds,
}: CreateHangerRequestModalProps) {
  const [comment, setComment] = useState<string>('');

  // Request a hanger
  const handleHangerRequest = () => {
    const commentToSend = comment.trim() === '' ? null : comment;

    createHangerRequest(fabricIds)
      .then((requestHangerResp) => {
        const hangerRequestIds = requestHangerResp.map(
          (request) => request.hanger_request_id
        );
        if (commentToSend === null || hangerRequestIds.length === 0) {
          onCancel();
        } else {
          const newChatData: CreateHangerMessage = {
            object_type: 1,
            object_ids: hangerRequestIds, // Use the hangerRequestId directly
            text: commentToSend,
          };
          return createChat(newChatData); // Return the promise
        }
      })
      .then((success) => {
        if (success) {
          setComment(''); // Reset the comment
          onCancel(); // Close the modal or reset state
        }
      });
  };

  return (
    <>
      <Modal
        title={i18n.t('headers:request_hanger')}
        open={visible}
        onCancel={onCancel}
        okText={i18n.t('buttons:send')}
        onOk={handleHangerRequest}
      >
        <p>{i18n.t('long_messages:request_hanger_modal_text')}</p>
        <TextArea
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Modal>
    </>
  );
}
