import { Image, Space } from 'antd';
import i18n from 'i18next';

import placeholderImage from '../../../assets/images/placeholder-image.png';
import { CertificationDownloadIcon } from '../../../elements/certifications/StyledCertifications';
import { FrontImageStyled } from '../../../elements/images/StyledImages';
import { ColourRecord } from '../../../pages/colours/ColoursPage';
import { formFieldsProp } from '../../../utils/fabrics/FabricsTypes';
import {
  priceParser,
  similarityScoreParser,
} from '../../../utils/fabrics/parseFabric';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import { getSelectedLabelsFromLocalStorage } from '../../../utils/fabrics/selectedFabricTableColumnsLabels';
import { parseTranslatedCompositions } from '../../../utils/parsers/parseTranslatedCompositions';
import { record } from '../../../utils/types/Types';
import { DisplayColour } from '../../DisplayColour';
import { DisplayCertifications } from '../../certification/DisplayCertifications';
import { BasicFields } from '../../fabric/FabricTypes';
import { FabricDetailsLink } from '../../fabric/detail/FabricDetailsLink';
import { EditFabricLink } from '../../fabric/edit/EditFabricLink';
import { DesktopObj } from '../TableTypes';

export type attachmentProp = {
  name: string;
  url: string;
  id: string;
};

export const selectableColumns = returnOrderedFields([
  'fabric_type',
  'construction',
  'compositions',
  'original_supplier',
  'name_from_original_supplier',
  'weight_grams_per_sqm',
  'width_cm',
  'description',
  'notes',
  'colours',
  'country_of_origin',
  'attachments',
  'colour_fastness',
  'wash_care',
  'piling',
  'gauge_inch',
  'yarn_count',
  'stretch',
  'functional_finish',
  'season',
  'creation_date',
  'moq_m',
  'mcq_m',
  'moq_sqm',
  'mcq_sqm',
  'moq_kg',
  'mcq_kg',
  'moq_piece',
  'mcq_piece',
  'stock_m',
  'stock_sqm',
  'stock_kg',
  'stock_piece',
  'weight_grams_per_m',
  'weight_grams_per_piece',
  'length_cm_per_piece',
  'updated_at',
  'created_at',
  'usage_category',
  'look',
  'special_yarn',
  'sustainability',
  'hand_feel',
  'repeat',
  'lace_structure',
  'pattern_design',
  'aesthetic_finish',
  'edge_finish',
  'price_per_sqm',
  'price_per_m',
  'price_per_kg',
  'price_per_piece',
  'lead_time_days',
  'ml_pattern_tags',
  'ml_description',
  'availability',
  'certifications',
  'image_url_back',
  'image_url_header',
  'image_url_macro',
  'coverage',
  'cuttable_width_cm',
  'lace_type',
  'supplier_brand_name',
]);

export const selectableDefaultColumnsLabels = [
  'fabric_type',
  'original_supplier',
  'price_per_m',
  'price_per_sqm',
  'price_per_m',
  'price_per_kg',
  'price_per_piece',
  'weight_grams_per_sqm',
  'width_cm',
  'compositions',
  'construction',
];

export const getSelectedColumns = (
  pageType: string,
  measurementUnit: string | null,
  role: string | null,
  selectableColumnNames: string[],
  account: string | null,
  includeSimilarity?: boolean
): DesktopObj[] => {
  const storedLabels = getSelectedLabelsFromLocalStorage(false);

  const selectedColumns = selectableColumns.filter((column) =>
    selectableColumnNames.includes(column.name)
  );

  const columns: DesktopObj[] = [
    {
      dataIndex: 'name',
      title: parseFabricTitles('name', measurementUnit),
      columnType: 'string',
      grouping: 'basic',
    },
    {
      dataIndex: 'image_url_front',
      title: parseFabricTitles('image_url_front', measurementUnit),
      grouping: 'basic',
      render: (_: unknown, record: record) => {
        const text = record.image_url_front;
        const imageKey = record.id + '_image_url_front';
        return (
          <FrontImageStyled
            key={imageKey}
            width={'8vw'}
            height={'8vw'}
            src={text ? text : placeholderImage}
            alt={parseFabricTitles('image_url_front', measurementUnit)}
          />
        );
      },
    },
  ];

  const selectedFabricProperties = selectedColumns.filter(
    (fabricProperty: BasicFields) => storedLabels.includes(fabricProperty.name)
  );

  //Push similarity column for image search which contains the score field
  if (includeSimilarity) {
    const similarityColumn = returnOrderedFields(['similarity_score']);
    selectedFabricProperties.push(...similarityColumn);
  }

  selectedFabricProperties.forEach((element: formFieldsProp) => {
    const obj: DesktopObj = {
      dataIndex: element.name,
      title: parseFabricTitles(element.name, measurementUnit),
      columnType: element.columnType,
      grouping:
        element.name === 'similarity_score' ? 'basic' : element.grouping,
    };

    if (element.name === 'similarity_score') {
      obj.render = (_: unknown, record: record) => {
        return similarityScoreParser(record['similarity_score'] as number);
      };
    } else if (element.currency === true) {
      obj.render = (_: unknown, record: record) => {
        return priceParser(
          record.currency as string,
          record[element.name as keyof record] as number | null
        );
      };
    } else if (element.name === 'compositions') {
      obj.render = (_, record: record) => {
        const translatedComposition = parseTranslatedCompositions(
          record.compositions,
          false
        );
        return <span>{translatedComposition}</span>;
      };
    } else if (element.name === 'colours') {
      obj.render = (_, record: record) => {
        const colours =
          (record[element.name as keyof record] as ColourRecord[]) || [];

        // Check if colours is an array and format it to match the structure expected by DisplayColour
        const formattedColours = Array.isArray(colours)
          ? colours.map((colour: ColourRecord) => ({
              title: colour.name,
              hexValue: colour.hex_value,
              tooltipShow: true, // Assuming tooltip should be shown for all colours
            }))
          : [];

        return formattedColours.length > 0 ? (
          <DisplayColour colours={formattedColours} />
        ) : null;
      };
    }
    if (element.name === 'attachments') {
      obj.render = (_, record: record) => {
        const attachments = record[element.name as keyof record];

        // Check if attachments is an array and is of the correct type
        if (Array.isArray(attachments)) {
          return (
            <div>
              {attachments.map((attachment, index) => {
                // Type assertion here
                const att = attachment as attachmentProp;
                return (
                  <div key={index}>
                    <Space>
                      {att.name}
                      <a
                        href={att.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CertificationDownloadIcon />
                      </a>
                    </Space>
                  </div>
                );
              })}
            </div>
          );
        } else {
          return null; // Return null if attachments is not valid
        }
      };
    } else if (element.fieldType === 'image') {
      obj.render = (_: unknown, record: record) => {
        const text = record[element.name as keyof record]; // Accessing using bracket notation
        const imageKey = record.id + '_' + element.name;
        return (
          <Image
            key={imageKey}
            width={'8vw'}
            height={'8vw'}
            src={text ? text : placeholderImage}
            alt={element.name}
            style={{
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        );
      };
    } else if (element.name === 'certifications') {
      obj.render = (_: unknown, record: record) => {
        return (
          <DisplayCertifications
            certArray={record.certifications}
            key={record.id}
          />
        );
      };
    }
    columns.push(obj);
  });

  if (pageType !== 'noAuth') {
    columns.push({
      title: i18n.t('column_titles:action'),
      dataIndex: 'action',
      fixed: 'right',
      grouping: 'manage',
      render: (_: unknown, record: record) => (
        <Space size="middle">
          {(pageType !== 'collection' || role === 'designer') && (
            <FabricDetailsLink pageType={pageType} record={record} />
          )}
          {role === 'supplier' && account !== 'viewer' && (
            <EditFabricLink pageType={pageType} record={record} />
          )}
        </Space>
      ),
    });
  }

  return columns;
};
