import { message } from 'antd';
import { isAxiosError } from 'axios';
import { CollectionBinBody } from '../../../components/bin/BinTypes';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Backend request to permanently delete selected collections from the bin.
 * If successful return true and display a success message.
 * If an error occurs, return false and display the error message.
 */
export const deleteCollection = async (
  body: CollectionBinBody
): Promise<boolean> => {
  let successFlag = false;
  try {
    const response: MsgProp = await api({
      method: 'DELETE',
      url: 'v1/collection',
      body: body,
    });
    successFlag = true;
    message.success(response.msg);
  } catch (error) {
    successFlag = false;
    if (isAxiosError(error)) {
      message.error(error.toString());
    }
  }
  return successFlag;
};
