import { message } from 'antd';
import i18n from 'i18next';
import { api } from '../api/api';

import { MsgProp } from '../../types/Types';

/**
 * Unshares a collection, specified by ID.
 */
export async function unshareCollection(
  collectionId: string
): Promise<MsgProp> {
  try {
    return await api({
      method: 'PUT',
      url: `v1/collection/${collectionId}/unshare`,
    }).then((res: MsgProp) => {
      message.success(
        i18n.t('long_messages:message_collection_unshared_success')
      );
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
