import { message } from 'antd';
import { hangerRequestResp } from '../../../components/table/TableTypes';
import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

/**
 * Sends a request for a hanger of the specified fabric via email,
 * without any comment.
 */
export const createHangerRequest = async (
  fabricIds: string[]
): Promise<hangerRequestResp[]> => {
  const data = {
    fabric_ids: fabricIds,
  };
  try {
    const response = await api({
      method: 'POST',
      url: 'v1/hanger_request',
      body: data,
    });
    message.success(i18n.t('long_messages:request_hanger_success_message'));
    return response;
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
};
