import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'i18next';
import { api } from '../api/api';

/**
 * Send a certification to the bin based on its name.
 */
export async function deleteToBinCertification(
  certId: string
): Promise<boolean> {
  try {
    await api({
      method: 'PUT',
      url: `v1/certification/${certId}/delete_to_bin`,
    });

    message.success(i18n.t('long_messages:message_cert_bin_success'));
    return true;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error(error.toString());
    }
    return false;
  }
}
