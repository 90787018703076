import { Button, Collapse, Drawer } from 'antd';
import { Dayjs } from 'dayjs';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';

import { dayjs } from '../../../plugins/dayjs';
import { Setter } from '../../../utils/types/Types';
import {
  BackendFilterProps,
  FilterValues,
  GenericFilterDrawerProps,
} from '../FilterTypes';
import { BooleanCheckboxFilter } from '../filterTemplate/BooleanCheckboxFilter';
import { CheckboxFilter } from '../filterTemplate/CheckboxFilter';
import { DateRangeFilter } from '../filterTemplate/DateRangeFilter';
import { createInitialChoicesOrRange } from './createInitialChoicesOrRange';

export function GenericFilterDrawer({
  initialData,
  setFilteredData,
  filterOpen,
  closeFilter,
  createInitialFilterValues,
  role,
}: GenericFilterDrawerProps) {
  const initialFilterValues = useMemo(
    () => createInitialFilterValues(role),
    []
  );
  const initialChoicesOrRange = useMemo(
    () => createInitialChoicesOrRange(initialFilterValues),
    [initialFilterValues]
  );
  const [currentFilterValues, setCurrentFilterValues] =
    useState<FilterValues>(initialFilterValues);

  useEffect(() => {
    setCurrentFilterValues(initialFilterValues);
  }, [initialFilterValues]);

  const [ChoicesOrRange, setChoicesOrRange] = useState(initialChoicesOrRange);

  useEffect(() => {
    const _getDateRange = (
      field: string,
      ChoicesOrRangeToUpdate: Record<string, unknown>,
      row: Record<string, unknown>
    ) => {
      const dayjsDate = dayjs(row[field] as string, 'YYYY-MM-DD');
      if (!row[field] || !dayjsDate.isValid()) return;
      if ((ChoicesOrRangeToUpdate[field] as Dayjs[]).length === 0)
        ChoicesOrRangeToUpdate[field] = [dayjsDate, dayjsDate];
      if (dayjsDate.isBefore((ChoicesOrRangeToUpdate[field] as Dayjs[])[0]))
        (ChoicesOrRangeToUpdate[field] as Dayjs[])[0] = dayjsDate;
      if (dayjsDate.isAfter((ChoicesOrRangeToUpdate[field] as Dayjs[])[1]))
        (ChoicesOrRangeToUpdate[field] as Dayjs[])[1] = dayjsDate;
    };

    const tempChoicesOrRange = structuredClone(initialChoicesOrRange);

    for (const row of initialData) {
      for (const [key, value] of Object.entries(initialFilterValues)) {
        if (value.filter_type === 'date') {
          _getDateRange(key, tempChoicesOrRange, row);
        } else if (value.filter_type === 'checkbox') {
          // Collect unique checkbox values
          const checkboxValues = new Set(tempChoicesOrRange[key] || []);
          checkboxValues.add(row[key]);
          tempChoicesOrRange[key] = Array.from(checkboxValues);
        }
      }
    }

    // Sort the date range values
    Object.keys(tempChoicesOrRange).forEach((key) => {
      if (initialFilterValues[key].filter_type === 'date') {
        tempChoicesOrRange[key].sort((a, b) => (a.isAfter(b) ? 1 : -1));
      }
    });

    setChoicesOrRange(tempChoicesOrRange);
    setCurrentFilterValues((previousState) => {
      const newState = { ...previousState };
      Object.keys(tempChoicesOrRange).forEach((key) => {
        newState[key] = {
          ...previousState[key],
          values: tempChoicesOrRange[key],
        };
      });
      return newState;
    });
  }, [initialData, initialChoicesOrRange, initialFilterValues]);

  const isDayjs = (value: unknown): value is Dayjs => {
    return dayjs.isDayjs(value);
  };

  const updateFilteredData = (filterValues: FilterValues) => {
    let filteredData = initialData;

    for (const [key, value] of Object.entries(filterValues)) {
      filteredData = filteredData.filter(
        (element) => value.has_null || element[key] !== null
      );

      if (value.filter_type === 'date') {
        filteredData = filteredData.filter((element) => {
          const elementDate = dayjs(element[key], 'YYYY-MM-DD');
          return (
            element[key] === null ||
            value.values.length === 0 ||
            (isDayjs(elementDate) && elementDate.isSameOrAfter(value.values[0]))
          );
        });
        filteredData = filteredData.filter((element) => {
          const elementDate = dayjs(element[key], 'YYYY-MM-DD');
          return (
            element[key] === null ||
            value.values.length === 0 ||
            (isDayjs(elementDate) &&
              elementDate.isSameOrBefore(value.values[1]))
          );
        });
      } else if (value.filter_type === 'checkbox') {
        if (value.values.length > 0) {
          filteredData = filteredData.filter((element) =>
            value.values.includes(element[key])
          );
        } else {
          filteredData = []; // No checkbox selected, filter out all data
        }
      } else if (value.filter_type === 'checkboxBoolean') {
        if (value.values.length > 0) {
          filteredData = filteredData.filter((element) => {
            return (
              (value.values.includes(true) && element[key] === true) ||
              (value.values.includes(false) && element[key] === false)
            );
          });
        } else {
          filteredData = []; // No checkbox selected, filter out all data
        }
      }
    }
    setFilteredData(filteredData);
  };

  useEffect(() => {
    updateFilteredData(currentFilterValues);
  }, [currentFilterValues]);

  const resetFilters = () => {
    setFilteredData(initialData);
    setCurrentFilterValues((previousState) => {
      const newState = { ...previousState };
      Object.keys(ChoicesOrRange).forEach((key) => {
        newState[key] = {
          ...previousState[key],
          values: ChoicesOrRange[key],
          has_null: true,
        };
      });

      return newState;
    });
  };

  const filterItems = () => {
    const items: { label: string; key: number; children: React.ReactNode }[] =
      [];

    Object.keys(currentFilterValues).forEach((key, index) => {
      if (currentFilterValues[key].filter_type === 'date') {
        items.push({
          label: i18n.t(`column_titles:${key}`),
          key: index,
          children: (
            <DateRangeFilter
              range={ChoicesOrRange[key]}
              field={key}
              currentFilterValues={currentFilterValues}
              setCurrentFilterValues={
                setCurrentFilterValues as Setter<
                  FilterValues | BackendFilterProps
                >
              }
              updateFilteredData={updateFilteredData}
            />
          ),
        });
      } else if (currentFilterValues[key].filter_type === 'checkbox') {
        items.push({
          label: i18n.t(`column_titles:${key}`),
          key: index,
          children: (
            <CheckboxFilter
              choices={ChoicesOrRange[key]} // Pass choices for the checkbox filter
              field={key}
              isTranslatable={true}
              currentFilterValues={currentFilterValues}
              setCurrentFilterValues={
                setCurrentFilterValues as Setter<
                  FilterValues | BackendFilterProps
                >
              }
              updateFilteredData={updateFilteredData}
            />
          ),
        });
      } else if (currentFilterValues[key].filter_type === 'checkboxBoolean') {
        items.push({
          label: i18n.t(`column_titles:${key}`),
          key: index,
          children: (
            <BooleanCheckboxFilter
              field={key}
              isTranslatable={true}
              currentFilterValues={currentFilterValues}
              setCurrentFilterValues={
                setCurrentFilterValues as Setter<
                  FilterValues | BackendFilterProps
                >
              }
              updateFilteredData={updateFilteredData}
              choices={ChoicesOrRange[key]}
            />
          ),
        });
      }
    });

    return items;
  };
  return (
    <Drawer
      open={filterOpen}
      placement="left"
      onClose={closeFilter}
      title={i18n.t('buttons:filter')}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <Button
        danger
        onClick={resetFilters}
        style={{
          width: '100%',
        }}
      >
        {i18n.t('buttons:reset_filters')}
      </Button>
      <Collapse items={filterItems()} bordered={false} />
    </Drawer>
  );
}
