import { axiosNoAuth } from '../../../plugins/axiosNoAuth';
/**
 * A template to make API with no Auth calls to axios with. Also includes general error
 * handling. Returns complete response, not just data.
 *
 * @param {object} - Object used to make the API call using axios
 * @returns {*} - Return of the API call
 */

interface apiProps {
  method: string;
  url: string;
  body?: object;
  headers?: object;
  params?: object;
}

export const api = async ({ method, url, body, headers, params }: apiProps) => {
  return axiosNoAuth
    .request({
      url: `${process.env.REACT_APP_BACKEND_DOBBY}${url}`,
      method,
      data: body,
      headers: headers,
      params,
    })
    .then((r) => r)
    .catch((error) => {
      throw new Error(error.response.data.msg);
    });
};
