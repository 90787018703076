import { message } from 'antd';
import i18n from 'i18next';
import { api } from '../api/api';
import { editCertBody, editCertResp } from './CertificationTypes';

/**
 * Edit a certficiation. it must be owned by the user's
 * supplier brand.
 */
export async function editCertification(
  id: string,
  body: editCertBody
): Promise<editCertResp> {
  try {
    return await api({
      method: 'PUT',
      url: `v1/certification/${id}`,
      body: body,
    }).then((res: editCertResp) => {
      message.success(i18n.t('long_messages:message_cert_updated_success'));
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
