import { message } from 'antd';
import { isAxiosError } from 'axios';
import { api } from '../api/api';
import { ApprovedBrands } from './AdminTypes';

/**
 * Get all approved supplier brands for a given designer brand.
 */
export async function getApprovedSupplierBrands(
  designerBrandId: string
): Promise<ApprovedBrands> {
  try {
    const response = await api({
      method: 'GET',
      url: `v1/superuser/brand/designer/${designerBrandId}/approved_supplier_brand`,
    });

    const approvedSupplierBrands = Object.entries(response).map(
      ([key, value]) => ({
        key: key,
        value: value,
      })
    ) as ApprovedBrands;
    return approvedSupplierBrands;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error(error.response?.data.msg);
    }
    throw error;
  }
}
