import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Send a fabric library to the bin based on its id.
 */
export async function deleteToBinFabricLib(libraryId: string): Promise<void> {
  try {
    await api({
      method: 'PUT',
      url: `v1/library/fabric/${libraryId}/delete_to_bin`,
    });
    message.success(i18n.t('long_messages:message_library_deleted_success'));
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
  }
}
