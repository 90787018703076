import { message } from 'antd';
import { hangerRequestResp } from '../../../components/table/TableTypes';
import { api } from '../api/api';

/**
 * Gets all available hanger requests for current user
 */
export async function getHangerRequests(): Promise<hangerRequestResp[]> {
  try {
    const response = await api({
      method: 'GET',
      url: 'v1/hanger_request',
    });
    return response;
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
