import { message } from 'antd';
import { api } from '../api/api';
import { ImageBulkUploadResponse } from './AdminTypes';

/**
 * Uploads images in bulk for a specified supplier and folder.
 */
export async function imageBulkUpload(
  supplierId: string,
  folderName: string
): Promise<ImageBulkUploadResponse | null> {
  const data = {
    supplier_brand_id: supplierId,
    folder_name: folderName,
  };

  try {
    const response = await api({
      method: 'PUT',
      url: 'v1/superuser/fabric/image_bulk_upload/upload_images',
      body: data,
    });
    // Check if the uploading was unsuccessful
    if (response.Errors) {
      const errorsString = JSON.stringify(response.Errors);
      message.error(errorsString);
      return {
        text: `${JSON.stringify(response.msg)}\n${errorsString}`,
        isSuccess: false,
      };
    } else {
      message.success('Bulk Image Folder successfully uploaded.');
      return {
        text: 'Bulk Image Folder successfully uploaded.',
        isSuccess: true,
      };
    }
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
      return { text: error.message, isSuccess: false };
    }
    return null;
  }
}
