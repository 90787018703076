import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TabTitle } from '../../components/TabTitle';
import { CreateFabricForm } from '../../components/fabric/create/CreateFabricForm';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { MainLayout } from '../../layouts/Main';
import i18n from '../../plugins/i18n';
import { RootState } from '../../utils/types/Types';

export function CreateFabricPage() {
  const { libraryId } = useParams<{ libraryId?: string }>();

  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  return (
    <MainLayout>
      <HeaderTitle title={i18n.t('page_titles:create_fabric_page')} />
      <TabTitle title={'Create Fabric'} />
      <CreateFabricForm libraryId={libraryId || ''} isMobile={isMobileFlag} />
    </MainLayout>
  );
}
