import { Button, List } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { SendMsgIcon } from '../../../elements/icons/StyledIcons';
import {
  ChatContainer,
  ChatSendMessageContainer,
  MessagesContainer,
  NewChatTextContainer,
} from '../../../elements/modals/StyledModal';
import i18n from '../../../plugins/i18n';
import { createChat } from '../../../utils/backendRequests/hangers/createChat';
import { getChatById } from '../../../utils/backendRequests/hangers/getChatById';
import { parseLocalDateTime } from '../../../utils/parsers/parseLocalDateTime';
import { stringValid } from '../../../utils/validations/stringValidator';
import { Modal } from '../../Modal';
import { hangerRequestResp } from '../../table/TableTypes';
import {
  CreateHangerMessage,
  MessageContent,
  MessageObject,
} from '../../table/hangersList/HangerTypes';

interface HangersChatProps {
  record: hangerRequestResp;
  getHangerData: () => void;
}

export const HangersChatModal = ({
  record,
  getHangerData,
}: HangersChatProps) => {
  const [chatId, setChatId] = useState<string | null>(record.chat_id || null);
  const [showChat, setShowChat] = useState(!!chatId);
  const [chatData, setChatData] = useState<MessageObject | null>(null); // Updated to use MessageObject or null
  const [newMessage, setNewMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // Create a ref for the message list
  const messageListRef = useRef<HTMLDivElement | null>(null);

  // Fetch chat messages when chat_id exists
  const fetchChat = async (prevChatId: string) => {
    const existingMessages: MessageObject = await getChatById(prevChatId);
    setChatData(existingMessages);
  };

  // Handle starting a new chat if chat_id is null
  const startChat = async () => {
    const newChatData: CreateHangerMessage = {
      object_type: 1,
      object_ids: [record.hanger_request_id],
      text: newMessage,
    };
    const success = await createChat(newChatData);
    if (!chatId && success.length > 0) {
      const newChatId = success[0].chat_id;
      setChatId(newChatId); // Update chatId in state
      fetchChat(newChatId);
      setNewMessage('');
    }
    if (chatId) {
      setShowChat(true);
    }
  };

  // Handle sending new messages
  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    if (stringValid(newMessage, 400, i18n.t('headers:message'), false)) {
      await startChat();
      setNewMessage('');
      if (chatId) {
        await fetchChat(chatId);
      } else {
        getHangerData();
      }
    }
  };

  // Fetch chat if chat_id exists
  useEffect(() => {
    if (chatId && showChat && isModalOpen) {
      fetchChat(chatId);
    }
  }, [chatId, showChat, isModalOpen]);

  // Scroll to the bottom of the message list
  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  // Trigger scrollToBottom when chatData changes
  useEffect(() => {
    scrollToBottom();
  }, [chatData]);

  // Scroll to the bottom when the modal opens
  useEffect(() => {
    if (isModalOpen) {
      scrollToBottom();
    } else {
      setChatData(null);
    }
  }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      {chatId !== null ? (
        <a role="button" onClick={showModal}>
          {i18n.t('buttons:view_chat')}
        </a>
      ) : (
        <a role="button" onClick={showModal}>
          {i18n.t('buttons:new_chat')}
        </a>
      )}
      <Modal
        title={i18n.t('headers:chat')}
        open={isModalOpen}
        width={700}
        onCancel={handleCancel}
        footer={null} // This removes the footer, including the cancel button.
      >
        <ChatContainer>
          {chatId && chatData && isModalOpen ? (
            <>
              <MessagesContainer ref={messageListRef}>
                {/* List of Chat Messages */}
                {chatData.messages.length > 0 && (
                  <List
                    dataSource={chatData.messages}
                    renderItem={(msg: MessageContent) => (
                      <List.Item key={msg.message_id}>
                        <div>
                          <strong>
                            {msg.user.name} ({msg.user.brand_name}):
                          </strong>{' '}
                          {msg.text} <br />
                          <small>{parseLocalDateTime(msg.created_at)}</small>
                        </div>
                      </List.Item>
                    )}
                  />
                )}
              </MessagesContainer>
              <ChatSendMessageContainer
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                enterButton={
                  <Button type="primary">
                    {i18n.t('buttons:send')}
                    <SendMsgIcon />
                  </Button>
                }
                onSearch={sendMessage} // Triggered when pressing Enter or clicking the button
                placeholder={i18n.t('long_messages:type_message_chat')}
              />
            </>
          ) : (
            <>
              <NewChatTextContainer>
                {i18n.t('long_messages:comment_for_supplier')}
              </NewChatTextContainer>
              <ChatSendMessageContainer
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                enterButton={
                  <Button type="primary">
                    {i18n.t('buttons:send')}
                    <SendMsgIcon />
                  </Button>
                }
                onSearch={startChat}
                placeholder={i18n.t('long_messages:type_message_chat')}
              />
            </>
          )}
        </ChatContainer>
      </Modal>
    </>
  );
};
