import { message } from 'antd';
import { TransformedFilterProps } from '../../../components/filter/FilterTypes';
import { GenericTableReqProps, TableStateObj } from '../../types/Types';
import { api } from '../api/api';
import { GetFabricsResponse } from './FabricTypes';

/**
 * Get an array of fabrics from a given array of their id.
 */
export const getFabricsByIds = async (
  fabricIds: string[],
  tableState?: TableStateObj,
  filterableColumns?: TransformedFilterProps
): Promise<GetFabricsResponse> => {
  if (fabricIds.length === 0)
    return {
      fabrics: [],
      fabrics_count: 0,
      selectable_columns: [],
      sortable_columns: [],
      filterable_columns: {},
    };

  const query = fabricIds.map((id) => `ids=${id}`).join('&');

  // Conditionally define params based on whether tableState is provided
  const params: GenericTableReqProps | undefined = tableState
    ? {
        page: tableState.current,
        page_size: tableState.pageSize,
        ...(tableState.sortField &&
          tableState.sortOrder && {
            sort_by: tableState.sortField,
            sort_order: tableState.sortOrder,
          }),
        ...(filterableColumns && {
          filter_values: JSON.stringify(filterableColumns),
        }),
      }
    : undefined;

  return api({
    method: 'GET',
    url: `v1/fabric?${query}`,
    params: params,
  }).catch((error) => {
    message.error(error.message);
  });
};
