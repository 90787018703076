import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'i18next';

import { api } from '../api/api';
/**
 * Share the project via User Ids
 */
export async function shareProject(projectId: string, userIds: string[]) {
  const data = { shared_with_user_ids: userIds };
  try {
    await api({
      method: 'PUT',
      url: `v1/project/${projectId}/share`,
      body: data,
      headers: {
        'content-type': 'application/json',
      },
    });
    message.success(i18n.t('long_messages:message_project_shared_success'));
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
  }
}
