import { message } from 'antd';
import { api } from '../api/api';

/**
 * Gets all chat data for a specified chatId.
 */
export const getChatById = async (chatId: string) => {
  try {
    const response = await api({
      method: 'GET',
      url: `v1/chat/${chatId}`,
    });
    return response;
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
};
