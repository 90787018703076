import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Update fabrics in a project, specified by name.
 */
export async function addProjectFabrics(
  projectId: string,
  newFabricIds: string[]
): Promise<boolean> {
  try {
    return await api({
      method: 'POST',
      url: `v1/project/${projectId}/fabric`,
      body: {
        fabric_ids: newFabricIds,
      },
    }).then(() => {
      message.success(i18n.t('long_messages:message_project_updated_success'));
      return true;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
}
