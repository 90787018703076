import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Modal } from '../../components/Modal';
import { TabTitle } from '../../components/TabTitle';
import { ExportSelectedRowsButton } from '../../components/fabric/ExportSelectedRowsButton';
import {
  BackendFilterProps,
  TransformedFilterProps,
} from '../../components/filter/FilterTypes';
import { FabricFilterDrawer } from '../../components/filter/fabric/FabricFilterDrawer';
import { FabricSwitchToggle } from '../../components/gallery/FabricSwitchToggle';
import { GalleryView } from '../../components/gallery/GalleryView';
import { SelectFabricColumns } from '../../components/modal/SelectFabricColumns';
import { SelectMobileFabricColumns } from '../../components/modal/SelectMobileFabricColumns';
import { AddToCollectionModal } from '../../components/modal/collectionModal/AddToCollectionModal';
import { LibraryType } from '../../components/modal/libraryModal/LibraryModalTypes';
import { MoveToLibraryModal } from '../../components/modal/libraryModal/MoveToLibraryModal';
import { intialDataProp } from '../../components/search/SearchTypes';
import { CompareButton } from '../../components/table/CompareButton';
import { FabricTableGeneric } from '../../components/table/FabricTableGeneric';
import { defaultPageSize } from '../../components/table/TableGeneric';
import { columnFields } from '../../components/table/TableTypes';
import { getSelectedColumns } from '../../components/table/fabricTable/ColumnsDesktop';
import { getSelectedMobileColumns } from '../../components/table/fabricTable/ColumnsMobile';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { Color } from '../../elements/base/Color';
import {
  ButtonBasic,
  DropdownButton,
  SelectFabricsButton,
} from '../../elements/buttons/StyledButtons';
import { ToggleButtonsContainer } from '../../elements/fabrics/fabricStyledElement';
import { MainLayout } from '../../layouts/Main';
import { routesNamedDict } from '../../routes';
import { deleteToBinFabric } from '../../utils/backendRequests/fabric/deleteToBinFabric';
import { getFabrics } from '../../utils/backendRequests/library/getFabrics';
import {
  getLibraries,
  LibraryListType,
} from '../../utils/backendRequests/list/libraries';
import { fabricPropertyArray } from '../../utils/fabrics/parseFabricFields';
import { getTranslatedValuesAndUpdateData } from '../../utils/fabrics/parseFabricValues';
import { record, RootAuth, RootState } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function FabricLibraryDetailsPage({ title }: TitleProp) {
  const { libraryId } = useParams(); // lib id received from the router
  const [data, setData] = useState<record[]>([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [translatedData, setTranslatedData] = useState<intialDataProp>([]);
  const [collectionModal, setCollectionModal] = useState({ visible: false });
  const [libraryModal, setLibraryModal] = useState({ visible: false });
  const [columnModal, setColumnModal] = useState({ visible: false });
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false); // Track select/deselect state
  const [fabricViewToggle, setFabricViewToggle] = useState<boolean>(() => {
    // Check if the value is already stored in localStorage
    const savedState = localStorage.getItem('fabricViewToggle');
    return savedState ? JSON.parse(savedState) : false; // Default to false if not found
  });
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const [selectableColumnNames, setSelectableColumnNames] = useState<string[]>(
    []
  );
  const [sortableColumnNames, setSortableColumnNames] = useState<string[]>([]);
  // Represents the range of filterable fields from the backend
  const [filterableFields, setFilterableFields] = useState<BackendFilterProps>(
    {}
  );
  // Represents the selected filter values to be sent to the backend
  const [filterableColumns, setFilterableColumns] =
    useState<TransformedFilterProps>({});

  // Pagination and Sorter state
  const [tableState, setTableState] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
    sortField: '',
    sortOrder: '',
  });

  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const [libraries, setLibraries] = useState<LibraryListType>([]);
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  // columns to be initialized based on current selection or local storage
  const [columns, setColumns] = useState<columnFields[]>([]);
  const [libraryName, setLibraryName] = useState<string>('');
  // Ids of selected fabrics according to the filtered selection
  const selectedIds = useMemo(() => {
    if (data.length === 0) {
      return [];
    } else {
      return selectedKeys
        .filter((rowKey) => data[(rowKey as number) - 1] !== undefined)
        .map((rowKey) => data[(rowKey as number) - 1]['id']);
    }
  }, [data, selectedKeys, fabricViewToggle]);

  const getLibraryList = async () => {
    const data = await getLibraries();
    setLibraries(data);
  };

  useEffect(() => {
    getLibraryList();
  }, []);

  useEffect(() => {
    if (isMobileFlag) {
      setColumns(
        getSelectedMobileColumns(
          'library',
          measurementUnit,
          selectableColumnNames,
          role,
          account
        )
      );
    } else {
      setColumns(
        getSelectedColumns(
          'library',
          measurementUnit,
          role,
          selectableColumnNames,
          account
        )
      );
    }
  }, [isMobileFlag, measurementUnit, selectableColumnNames]);

  async function getFabricsFromLibrary() {
    const res = await getFabrics(
      String(libraryId),
      tableState,
      filterableColumns
    );
    if (res) {
      setData(res.fabrics);
      setTableState((prevState) => ({
        ...prevState,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
      if (Object.keys(filterableFields).length === 0) {
        setFilterableFields(res.filterable_columns);
      }
    }
  }

  async function getFilteredFabricsFromLibrary() {
    setTableState((prevState) => ({
      ...prevState,
      current: 1,
    }));
    const res = await getFabrics(
      String(libraryId),
      tableState,
      filterableColumns
    );
    if (res) {
      setData(res.fabrics);
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
    }
  }

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      data,
      fabricPropertyArray
    ) as intialDataProp;
    setTranslatedData(translationData);
  }, [data]);

  // Get Filtered data when selected filters values change
  useEffect(() => {
    // Check if data has items and filterableFields is not an empty object
    // filterableFields represents the range of filterable fields from the backend
    if (data.length > 0 && Object.keys(filterableFields).length > 0) {
      getFilteredFabricsFromLibrary();
    }
  }, [filterableColumns]);

  // Resets selected keys when filter updates data
  useEffect(() => {
    setSelectedKeys([]);
  }, [data]);

  // Set Library Name
  useEffect(() => {
    if (libraries && libraries.length > 0) {
      const currentLibrary = libraries.find(
        (library: LibraryType) => library.id === libraryId
      );
      if (currentLibrary) {
        setLibraryName(currentLibrary.name);
      }
    }
  }, [libraries, libraryId]);

  // Get data on page load or resets data when all data filtered out
  useEffect(() => {
    if (data.length === 0) {
      getFabricsFromLibrary();
    }
  }, [filterableColumns, libraryId]);

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: onSelectChange as (
      selectedRowKeys: React.Key[],
      selectedRows: record[]
    ) => void,
  };

  const hasSelected = selectedKeys.length > 0;

  // Delete fabrics
  const handleConfirm = async () => {
    const data = { ids: selectedIds };
    deleteToBinFabric(data, navigate, true);
    setModalVisible(false); // Close the modal after deletion
  };

  // Load data on pagination or sorter change
  useEffect(() => {
    if (data.length > 0) getFabricsFromLibrary();
  }, [
    tableState.current,
    tableState.pageSize,
    tableState.sortField,
    tableState.sortOrder,
  ]);

  const handleToggleChange = (checked: boolean) => {
    setFabricViewToggle(checked);
    // Store the toggle value in localStorage
    localStorage.setItem('fabricViewToggle', JSON.stringify(checked));
  };

  useEffect(() => {
    // Update isSelectAll based on whether all items are selected
    const isAllSelected = selectedKeys.length === translatedData.length;
    setIsSelectAll(isAllSelected);
  }, [fabricViewToggle, selectedKeys, translatedData.length]);

  // Function to handle "Select All" / "Deselect All" toggle
  const handleSelectAllToggle = () => {
    if (isSelectAll) {
      // Deselect all
      setSelectedKeys([]);
    } else {
      // Select all
      // +1 to align with the index from the Ant Design table, which starts from 1
      setSelectedKeys(translatedData.map((_, index) => index + 1));
    }
    setIsSelectAll(!isSelectAll); // Toggle the button state
  };

  const items = [
    // Conditional addition of the create button
    ...(role === 'supplier' && account !== 'viewer'
      ? [
          {
            key: 'create-fabric',
            label: (
              <DropdownButton
                onClick={() =>
                  navigate(
                    routesNamedDict.FABRICS_CREATE_FABRIC.replace(
                      ':libraryId',
                      String(libraryId)
                    )
                  )
                }
              >
                {i18n.t('buttons:create')}
              </DropdownButton>
            ),
          },
        ]
      : []),
    {
      key: 'filter',
      label: (
        <DropdownButton
          disabled={data.length === 0}
          onClick={() => setFilterOpen(true)}
        >
          {i18n.t('buttons:filter')}
        </DropdownButton>
      ),
    },
    {
      key: 'compare',
      label: (
        <CompareButton
          filteredData={data}
          selectedRowKeys={selectedKeys}
          isMobile={true}
        />
      ),
    },
    {
      key: 'select-columns',
      label: (
        <DropdownButton
          disabled={data.length === 0 || fabricViewToggle}
          onClick={() => setColumnModal({ visible: true })}
        >
          {i18n.t('buttons:select_columns')}
        </DropdownButton>
      ),
    },
    {
      key: 'export',
      label: (
        <ExportSelectedRowsButton
          disabled={!hasSelected}
          data={data}
          selectedRowKeys={selectedKeys}
          exportName={libraryName}
        />
      ),
    },
    {
      key: 'move-to-library',
      label: (
        <DropdownButton
          disabled={!hasSelected}
          onClick={() => setLibraryModal({ visible: true })}
        >
          {i18n.t('buttons:move_to_library')}
        </DropdownButton>
      ),
    },
    {
      key: 'add-to-collection',
      label: account !== 'viewer' && (
        <DropdownButton
          disabled={!hasSelected}
          onClick={() => setCollectionModal({ visible: true })}
        >
          {i18n.t('buttons:add_to_collection')}
        </DropdownButton>
      ),
    },
    {
      key: 'delete',
      label: (
        <DropdownButton
          disabled={!hasSelected}
          onClick={() => setModalVisible(true)}
          danger
        >
          {i18n.t('buttons:delete')}
        </DropdownButton>
      ),
      danger: true,
    },
  ];

  return (
    <MainLayout>
      <HeaderTitle
        title={`${i18n.t('page_titles:fabric_library_page')} "${libraryName}"`}
      />
      <TabTitle title={title} id={libraryId} />
      {libraries && libraries.length > 0 && (
        <MoveToLibraryModal
          fabricIds={selectedIds}
          modal={libraryModal}
          setModal={setLibraryModal}
          getFabrics={getFabricsFromLibrary}
          currentLibraryId={String(libraryId)}
          libraries={libraries}
          getLibraryList={getLibraryList}
        />
      )}
      <AddToCollectionModal
        fabricIds={selectedIds}
        modal={collectionModal}
        setModal={setCollectionModal}
      />
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={isMobileFlag ? 8 : undefined}>
          {isMobileFlag ? (
            <Dropdown menu={{ items }}>
              <ButtonBasic>
                <Space>
                  {i18n.t('buttons:Options')}
                  <DownOutlined />
                </Space>
              </ButtonBasic>
            </Dropdown>
          ) : (
            <Space direction="horizontal" size="small">
              {role === 'supplier' && account !== 'viewer' && (
                <ButtonBasic
                  onClick={() =>
                    navigate(
                      routesNamedDict.FABRICS_CREATE_FABRIC.replace(
                        ':libraryId',
                        String(libraryId)
                      )
                    )
                  }
                >
                  {i18n.t('buttons:create')}
                </ButtonBasic>
              )}
              <ButtonBasic
                onClick={() => setFilterOpen(true)}
                disabled={
                  data.length === 0 &&
                  Object.keys(filterableColumns).length === 0
                }
              >
                {i18n.t('buttons:filter')}
              </ButtonBasic>
              <CompareButton
                filteredData={translatedData}
                selectedRowKeys={selectedKeys}
              />
              <ButtonBasic
                onClick={() => setColumnModal({ visible: true })}
                disabled={data.length === 0 || fabricViewToggle}
              >
                {i18n.t('buttons:select_columns')}
              </ButtonBasic>
              <ExportSelectedRowsButton
                disabled={!hasSelected}
                data={translatedData}
                selectedRowKeys={selectedKeys}
                exportName={libraryName}
              />
              {account !== 'viewer' && (
                <>
                  <ButtonBasic
                    block
                    disabled={!hasSelected}
                    onClick={() => setLibraryModal({ visible: true })}
                  >
                    {i18n.t('buttons:move_to_library')}
                  </ButtonBasic>
                  <ButtonBasic
                    block
                    disabled={!hasSelected}
                    onClick={() => setCollectionModal({ visible: true })}
                  >
                    {i18n.t('headers:add_to_collection')}
                  </ButtonBasic>
                </>
              )}
              {role === 'supplier' && account !== 'viewer' && (
                <ButtonBasic
                  disabled={!hasSelected}
                  danger
                  onClick={() => setModalVisible(true)}
                >
                  {i18n.t('buttons:delete')}
                </ButtonBasic>
              )}
            </Space>
          )}
          <Modal
            title={i18n.t('headers:confirm_deletion')}
            open={modalVisible}
            onOk={handleConfirm}
            onCancel={() => setModalVisible(false)}
            okText={i18n.t('buttons:yes')}
            cancelText={i18n.t('buttons:no')}
            okButtonProps={{
              style: { backgroundColor: Color.Red },
            }}
          >
            <p>{i18n.t('long_messages:message_confirm_fabrics_deletion')}</p>
          </Modal>
        </Col>
        <ToggleButtonsContainer>
          <Row align="middle" gutter={8}>
            {fabricViewToggle && (
              <Col>
                <SelectFabricsButton
                  onClick={() => handleSelectAllToggle()}
                  disabled={translatedData.length === 0}
                >
                  {isSelectAll
                    ? i18n.t('headers:deselect_all')
                    : i18n.t('headers:select_all')}
                </SelectFabricsButton>
              </Col>
            )}
            <Col>
              <FabricSwitchToggle
                fabricViewToggle={fabricViewToggle}
                handleToggleChange={handleToggleChange}
              />
            </Col>
          </Row>
        </ToggleButtonsContainer>
      </Row>
      {isMobileFlag ? (
        <SelectMobileFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'library'}
          selectableColumnNames={selectableColumnNames}
        />
      ) : (
        <SelectFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'library'}
          selectableColumnNames={selectableColumnNames}
        />
      )}
      <Row>
        <FabricFilterDrawer
          filterOpen={filterOpen}
          closeFilter={() => setFilterOpen(false)}
          setFilterableColumns={setFilterableColumns}
          filterableFields={filterableFields}
        />
        {fabricViewToggle ? (
          <GalleryView
            fabrics={translatedData}
            tableState={tableState}
            setTableState={setTableState}
            isMobile={isMobileFlag}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            pageType={'library'}
          />
        ) : (
          <FabricTableGeneric
            rowSelection={rowSelection}
            columns={columns}
            data={translatedData}
            isBackendPagination={true}
            setTableState={setTableState}
            tableState={tableState}
            sortableColumns={sortableColumnNames}
          />
        )}
      </Row>
    </MainLayout>
  );
}
