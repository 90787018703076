import { message } from 'antd';
import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

/**
 * Update the name and/or description of a project.
 */
export async function updateProjectDetails(
  id: string,
  newName: string | null = null,
  newDescription: string | null = null
): Promise<boolean> {
  let data = {};
  if (newName) {
    data = { name: newName, description: newDescription };
  }
  try {
    return await api({
      method: 'PUT',
      url: `v1/project/${id}`,
      body: data,
    }).then(() => {
      message.success(i18n.t('long_messages:message_project_updated_success'));
      return true;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
}
