import { message } from 'antd';
import { api } from '../api/api';
import { SharedLinksResponse } from './CollectionTypes';

/**
 * Gets all sharable links information available for
 * users supplier brand
 */
export async function getShareableLinks(): Promise<SharedLinksResponse> {
  try {
    return await api({
      method: 'GET',
      url: 'v1/shareable_link/collection',
    }).then((res: SharedLinksResponse) => {
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
