import { message } from 'antd';
import { CreateHangerMessage } from '../../../components/table/hangersList/HangerTypes';
import { api } from '../api/api';

/**
 * Creates or updates a hanger requests optional chat
 */

export type CreateMessageResp = {
  user: {
    name: string;
    brand_name: string;
  };
  text: string;
  created_at: string;
  chat_id: string;
  message_id: string;
}[];

export const createChat = async (
  newChatData: CreateHangerMessage
): Promise<CreateMessageResp> => {
  try {
    const res = await api({
      method: 'POST',
      url: 'v1/chat/message',
      body: newChatData,
    });
    return res as CreateMessageResp;
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
};
