import { Button, Checkbox, Col, Pagination, Row, Space } from 'antd';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  SelectableFieldContainer,
  SelectFieldsFieldsContainer,
} from '../../elements/modals/StyledModal';
import { formFieldsProp } from '../../utils/fabrics/FabricsTypes';
import { parseFabricTitles } from '../../utils/fabrics/parseFabricFields';
import {
  getSelectedLabelsFromLocalStorage,
  setSelectedLabelsInStorage,
} from '../../utils/fabrics/selectedFabricTableColumnsLabels';
import { RootAuth } from '../../utils/types/Types';
import { Modal } from '../Modal';
import {
  selectableColumns as columns,
  getSelectedColumns,
  selectableDefaultColumnsLabels,
} from '../table/fabricTable/ColumnsDesktop';
import { SelectFabricColumnsProps } from './ModalTypes';

// Updated max groups per page
const MAX_GROUPS_PER_PAGE = 2; // Maximum of 2 groups per page (except for 'basic')

export function SelectFabricColumns({
  modal,
  setModal,
  setSelectedCol,
  pageType,
  selectableColumnNames,
  includeSimilarity = false,
}: SelectFabricColumnsProps) {
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const [selectedLabels, setSelectedLabels] = useState(
    getSelectedLabelsFromLocalStorage(false)
  );
  const [selectAllMode, setSelectAllMode] = useState(true);
  const [filteredColumns, setFilteredColumns] = useState<formFieldsProp[] | []>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1); // Pagination state

  useEffect(() => {
    const selectedColumnNames = columns.filter((column) =>
      selectableColumnNames.includes(column.name)
    );
    setFilteredColumns(selectedColumnNames);
  }, [selectableColumnNames]);

  const handleUpdateColumn = (selectColumn: string) => {
    const updatedColumns = selectedLabels.includes(selectColumn)
      ? selectedLabels.filter(
          (selectedColumn) => selectedColumn !== selectColumn
        )
      : [...selectedLabels, selectColumn];

    setSelectedLabels(updatedColumns);
  };

  const handleDefault = () => {
    setSelectedLabels(selectableDefaultColumnsLabels);
  };

  const handleCancel = () => {
    const storedLabels = getSelectedLabelsFromLocalStorage(false);
    setSelectedLabels(storedLabels);
    setModal({ visible: false });
  };

  const handleOk = () => {
    setSelectedLabelsInStorage(selectedLabels, false);
    const columns = getSelectedColumns(
      pageType,
      measurementUnit,
      role,
      selectableColumnNames,
      account,
      includeSimilarity
    );
    setSelectedCol(columns);
    setModal({ visible: false });
  };

  const handleToggleAll = () => {
    if (selectAllMode) {
      setSelectedLabels(filteredColumns.map((col) => col.name));
    } else {
      setSelectedLabels([]);
    }
  };

  useEffect(() => {
    setSelectAllMode(selectedLabels.length !== filteredColumns.length);
  }, [selectedLabels]);

  const groupColumnsByType = (columns: formFieldsProp[]) => {
    return columns.reduce(
      (acc, column) => {
        const group = column.grouping as string;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(column);
        return acc;
      },
      {} as Record<string, formFieldsProp[]>
    );
  };

  const groupedColumns = groupColumnsByType(filteredColumns);

  const getPaginatedColumns = (
    groupedColumns: Record<string, formFieldsProp[]>,
    currentPage: number
  ) => {
    const groups = Object.entries(groupedColumns);

    // Separate basic group from others
    const basicGroup = groups.find(([group]) => group === 'basic');
    const otherGroups = groups.filter(([group]) => group !== 'basic');

    // Adjust pagination based on group types
    const isBasicPage = currentPage === 1 && basicGroup;

    // Calculate start and end indices for pagination
    const startIndex = isBasicPage
      ? 0
      : (currentPage - 2) * MAX_GROUPS_PER_PAGE; // Subtract 2 for other groups
    const endIndex = startIndex + MAX_GROUPS_PER_PAGE;

    // Get the paginated groups from the other groups
    const paginatedOtherGroups = otherGroups
      .slice(startIndex, endIndex)
      .map(([group, columns]) => ({
        group,
        columns,
      }));

    // Combine groups: Add basic group (if it's a basic page) and an empty grouping
    const combinedGroups = isBasicPage
      ? [
          { group: basicGroup[0], columns: basicGroup[1] }, // Add the basic group
        ]
      : paginatedOtherGroups; // If not basic page, just return other groups

    // Calculate total pages
    const totalPages = Math.ceil(
      // Count the groups for pagination
      (basicGroup ? 1 : 0) + Math.ceil(otherGroups.length / MAX_GROUPS_PER_PAGE)
    );

    return { combinedGroups, totalPages }; // Return both combined groups and total pages
  };

  const { combinedGroups, totalPages } = getPaginatedColumns(
    groupedColumns,
    currentPage
  );

  const handleGroupSelectAll = (group: string) => {
    const groupColumns = groupedColumns[group];
    const groupSelected = groupColumns.every((col) =>
      selectedLabels.includes(col.name)
    );

    if (groupSelected) {
      setSelectedLabels(
        selectedLabels.filter(
          (label) => !groupColumns.some((col) => col.name === label)
        )
      );
    } else {
      setSelectedLabels([
        ...selectedLabels,
        ...groupColumns.map((col) => col.name),
      ]);
    }
  };

  const renderGroups = combinedGroups.map(({ group, columns }) => {
    // Split the columns into three separate arrays for each column
    const columnCount = 3;
    const columnsPerGroup = Math.ceil(columns.length / columnCount);
    const columnsInGroups = Array.from({ length: columnCount }, (_, i) =>
      columns.slice(i * columnsPerGroup, (i + 1) * columnsPerGroup)
    );

    return (
      <Col span={24} key={group}>
        <Space>
          <h4 style={{ margin: '0 0 0px 0' }}>{i18n.t(`headers:${group}`)}</h4>
          <Checkbox
            checked={columns.every((col) => selectedLabels.includes(col.name))}
            onChange={() => handleGroupSelectAll(group)}
          ></Checkbox>
        </Space>
        <Row style={{ marginTop: '10px' }} gutter={[8, 8]}>
          {/* Render each group of columns */}
          {columnsInGroups.map((colGroup, colIndex) => (
            <Col
              key={`group-col-${group}-${colIndex}`}
              span={Math.floor(24 / columnCount)} // Each column takes up 1/3 of the width
            >
              {colGroup.map((col, index) => (
                <SelectableFieldContainer key={`${group}-${col.name}-${index}`}>
                  <Checkbox
                    checked={selectedLabels.includes(col.name)}
                    onChange={() => handleUpdateColumn(col.name)}
                  />
                  <span style={{ marginLeft: '8px' }}>
                    {parseFabricTitles(col.name, measurementUnit)}
                  </span>
                </SelectableFieldContainer>
              ))}
            </Col>
          ))}
        </Row>
      </Col>
    );
  });

  return (
    <Modal
      title={i18n.t('headers:select_fabric_columns')}
      width="900px"
      open={modal.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <React.Fragment key="footer">
          <Button onClick={handleToggleAll}>
            {selectAllMode
              ? i18n.t('buttons:select_all')
              : i18n.t('buttons:deselect_all')}
          </Button>
          <Button onClick={handleDefault}>{i18n.t('buttons:default')}</Button>
          <Button danger onClick={handleCancel}>
            {i18n.t('buttons:cancel')}
          </Button>
          <Button type="primary" onClick={handleOk}>
            {i18n.t('buttons:ok')}
          </Button>
        </React.Fragment>,
      ]}
    >
      <Row gutter={[8, 8]}>{renderGroups}</Row>
      <SelectFieldsFieldsContainer>
        <Pagination
          current={currentPage}
          total={totalPages}
          pageSize={1} // Each page shows one group
          onChange={(page) => setCurrentPage(page)}
          style={{ marginTop: '20px', textAlign: 'center' }}
        />
      </SelectFieldsFieldsContainer>
    </Modal>
  );
}
