import { message } from 'antd';
import i18n from 'i18next';
import { api } from '../api/api';
import { CreateCollectionLinkProps } from './CollectionTypes';

/**
 * Create a new shareable link for a collection that will
 * be sent to a provided email address.
 */
export async function createCollectionLink({
  collectionId,
  sharedEmail,
}: CreateCollectionLinkProps): Promise<boolean> {
  const data = {
    shared_email: sharedEmail,
  };
  try {
    await api({
      method: 'POST',
      url: `v1/shareable_link/collection/${collectionId}`,
      body: data,
    });
    message.success(
      i18n.t('long_messages:message_collection_link_created_success')
    );
    return true;
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
}
