import { Button, Space } from 'antd';
import i18n from 'i18next';

import placeholderImage from '../../../assets/images/placeholder-image.png';
import { FrontImageStyled } from '../../../elements/images/StyledImages';
import { SupplierType } from '../../../utils/backendRequests/suppliers/supplierTypes';

export const SupplierColumns = (
  account: string | null,
  editComment: (record: SupplierType) => void,
  removeSupplier: (supplierId: string) => void
) => {
  const commonColumns = [
    {
      dataIndex: 'name',
      title: i18n.t('common:name'),
      columnType: 'string',
    },
    {
      dataIndex: 'logo_image_url',
      title: i18n.t('column_titles:logo'),
      render: (text: string) => (
        <FrontImageStyled
          width={'8vw'}
          height={'8vw'}
          src={text ? text : placeholderImage}
        />
      ),
    },
    {
      dataIndex: 'address',
      title: i18n.t('column_titles:address'),
    },
    {
      dataIndex: 'city',
      title: i18n.t('column_titles:city'),
      columnType: 'string',
    },
    {
      dataIndex: 'country',
      title: i18n.t('column_titles:country'),
      columnType: 'string',
    },
    {
      dataIndex: 'website',
      title: i18n.t('column_titles:website'),
      render: (name: string) => <a href={name}>{name}</a>,
    },
    {
      dataIndex: 'comment_from_designer_brand',
      title: i18n.t('column_titles:comment'),
    },
  ];

  const columnsWithActions = [
    ...commonColumns,
    {
      title: i18n.t('column_titles:action'),
      fixed: 'right',
      render: (record: SupplierType) => {
        return (
          <Space size="middle">
            {account !== 'viewer' && (
              <>
                <a role="button" onClick={() => editComment(record)}>
                  {i18n.t('buttons:edit')}
                </a>
                <Button
                  danger
                  role="button"
                  onClick={() => removeSupplier(record.id)}
                >
                  {i18n.t('buttons:remove')}
                </Button>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  return account !== 'viewer' ? columnsWithActions : commonColumns;
};
