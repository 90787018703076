import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

/**
 * Deletes a shared link from a collection given the
 * Links Collection ID and Link ID to delete.
 */
export const deleteShareableLink = async (
  collectionId: string,
  linkId: string
): Promise<boolean> => {
  try {
    await api({
      method: 'DELETE',
      url: `v1/shareable_link/${linkId}/collection/${collectionId}`,
    });
    message.success(
      i18n.t('long_messages:message_collection_link_deleted_successfully')
    );
    return true;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error(error.toString());
    }
    return false;
  }
};
