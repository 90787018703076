import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'i18next';

import { api } from '../api/api';
/**
 * Send project to bin.
 * After a project is successfully binned, the project
 * list stored in redux is refreshed.
 */
export async function binProject(id: string) {
  try {
    await api({
      method: 'PUT',
      url: `v1/project/${id}/delete_to_bin`,
    });
    message.success(i18n.t('long_messages:message_project_bin_success'));
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
  }
}
