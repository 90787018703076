import { Button, Form } from 'antd';
import i18n from 'i18next';
import { useMemo, useState } from 'react';
import { FormWrapper } from '../../../elements/wrapper/FormWrapper';
import { createCertBody } from '../../../utils/backendRequests/certifications/CertificationTypes';
import { createCertification } from '../../../utils/backendRequests/certifications/createCertifications';
import { Modal } from '../../Modal';
import { FormItems } from '../../form/FormItems';
import { SetPropsWithDropDownObj } from '../../form/FormTypes';
import { CreateCertificationModalProps } from './CertificationModalTypes';
import { certificationFields } from './certificationFields';
import { certificationFieldRules } from './certificationFormRules';

type dropDownDataType = {
  certification_type: {
    value: number;
    label: string;
  }[];
};

export function CreateCertificationModal({
  modal,
  setModal,
  onSuccess,
}: CreateCertificationModalProps) {
  const [form] = Form.useForm();
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState<
    dropDownDataType | Record<string, never>
  >({});

  const formRulesConst = useMemo(
    () => certificationFieldRules(certificationFields),
    []
  );

  const handleCancel = () => {
    setModal({ visible: false });
    form.resetFields();
  };

  const handleSubmit = async (values: createCertBody) => {
    const createResponse = await createCertification(values);
    if (createResponse) {
      onSuccess();
      setModal({
        visible: false,
      });
    }
  };

  // Generic function to translate field titles
  const parseFieldTitle = (fieldName: string) => {
    return i18n.t(`${fieldName}`);
  };

  return (
    <Modal
      title={i18n.t('headers:new_certification')}
      open={modal.visible}
      onCancel={handleCancel}
      footer={null}
    >
      <FormWrapper>
        <Form form={form} onFinish={handleSubmit}>
          <FormItems
            formFields={certificationFields}
            propsWithDropDownObj={propsWithDropDownObj}
            setPropsWithDropDownObj={
              setPropsWithDropDownObj as SetPropsWithDropDownObj
            }
            formRules={formRulesConst}
            parseFieldTitle={parseFieldTitle}
          />
          <Form.Item wrapperCol={{ offset: 20 }}>
            <Button type="primary" htmlType="submit">
              {i18n.t('buttons:submit')}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  );
}
