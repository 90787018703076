import { message } from 'antd';
import { isAxiosError } from 'axios';
import { api } from '../api/api';
import { UpdateApprovedBrandsProps } from './AdminTypes';

/**
 * Update approved supplier brands for a given designer brand.
 */
export async function updateApprovedBrands({
  designerBrandId,
  supplierId,
}: UpdateApprovedBrandsProps): Promise<void> {
  const data = {
    supplier_brand_id: supplierId,
  };
  try {
    await api({
      method: 'POST',
      url: `v1/superuser/brand/designer/${designerBrandId}/approved_supplier_brand`,
      body: data,
    });
    message.success('Approved supplier brands updated successfully.');
  } catch (error) {
    if (isAxiosError(error)) {
      message.error(error.response?.data.msg);
    }
  }
}
