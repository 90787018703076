import { Space, message } from 'antd';
import i18n from 'i18next';
import { getCertObj } from '../../../utils/backendRequests/certifications/CertificationTypes';
import { CertStatusRender } from '../../certification/CertStatusRender';
import {
  CertCoulumnRecord,
  columnFields,
  editCertClickProp,
} from '../TableTypes';
import { BinCertificationButton } from './BinCertificationButton';
import { CreateOrUpdateFileModal } from './CreateOrUpdateFileModal';

const handleApplyAllFabrics = () => {
  message.info(i18n.t('long_messages:message_apply_all_fabrics_wip'));
};

export const ColumnsCert = (
  editCertOnClick: editCertClickProp,
  brandName: string | null,
  getCertifications: () => Promise<void>,
  account: string | null
) => {
  const commonColumns: columnFields[] = [
    {
      dataIndex: 'name',
      title: i18n.t('column_titles:certification_name'),
      columnType: 'string',
    },
    {
      dataIndex: 'is_owner',
      title: i18n.t('column_titles:owner'),
      columnType: 'boolean',
      render: (is_owner: boolean) => {
        return is_owner
          ? brandName
            ? brandName
            : i18n.t('common:company')
          : i18n.t('common:common');
      },
    },
    {
      dataIndex: 'certification_type',
      name: 'certification_type',
      title: i18n.t('column_titles:certification_type'),
      columnType: 'string',
      namespaceKey: 'certification_type_values',
      isTranslatable: true,
    },
    {
      dataIndex: 'url',
      title: i18n.t('column_titles:file'),
      render: (_: string, record: getCertObj) => {
        return (
          record.url && (
            <a href={record.url} target="_blank" rel="noreferrer">
              {i18n.t('buttons:download')}
            </a>
          )
        );
      },
    },
    {
      dataIndex: 'is_name_verified',
      title: i18n.t('column_titles:name_verified'),
      columnType: 'boolean',
    },
    {
      dataIndex: 'is_company_verified',
      title: i18n.t('column_titles:company_verified'),
      columnType: 'boolean',
    },
    {
      dataIndex: 'expiry_date',
      title: i18n.t('column_titles:expiry_date'),
      columnType: 'date',
    },
    {
      dataIndex: 'status',
      title: i18n.t('column_titles:status'),
      columnType: 'string',
      render: (status: string) => <CertStatusRender status={status} showNull />,
    },
  ];

  const columnsWithActions = [
    ...commonColumns,
    {
      title: i18n.t('column_titles:action'),
      fixed: 'right',
      render: (_: unknown, record: CertCoulumnRecord) => {
        return (
          <Space size="middle">
            {account !== 'viewer' && (
              <>
                <CreateOrUpdateFileModal
                  record={record}
                  onSuccess={getCertifications}
                />

                {record.is_owner && (
                  <>
                    <a onClick={() => editCertOnClick(record)} role="button">
                      {i18n.t('buttons:edit')}
                    </a>
                    <BinCertificationButton
                      certId={record.id}
                      getCertifications={getCertifications}
                    />
                  </>
                )}
                <a onClick={handleApplyAllFabrics} role="button">
                  {i18n.t('buttons:apply_all')}
                </a>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const columnsWithoutActions = [...commonColumns];

  return account !== 'viewer' ? columnsWithActions : columnsWithoutActions;
};
