import i18n from 'i18next';
import { Link } from 'react-router-dom';

import {
  GreenCheckedIcon,
  RedUnCheckedIcon,
} from '../../../elements/icons/StyledIcons';
import { FrontImageStyled } from '../../../elements/images/StyledImages';
import { ImageSearchListResponse } from '../../../utils/backendRequests/imageSearch/ImageSearchTypes';

export const textColumns = [
  {
    dataIndex: 'text_query',
    title: i18n.t('column_titles:text_query'),
    columnType: 'string',
  },
  {
    dataIndex: 'created_at',
    title: i18n.t('column_titles:created_at'),
    columnType: 'date',
  },
  {
    dataIndex: 'result_items_count',
    title: i18n.t('column_titles:number_of_results'),
    columnType: 'number',
  },
  {
    dataIndex: 'are_ml_fields_excluded',
    title: i18n.t('column_titles:is_ai_included'),
    columnType: 'boolean',
    // TODO: can we invert this value on the backend to not require this render logic?
    render: (value: boolean) => {
      const are_ml_fields_included = !value;
      if (are_ml_fields_included === true) {
        return <GreenCheckedIcon />;
      } else if (are_ml_fields_included === false) {
        return <RedUnCheckedIcon />;
      } else {
        return null;
      }
    },
  },
];

export const imageColumns = [
  {
    dataIndex: 'image_url_1',
    title: i18n.t('column_titles:front_image'),
    render: (url: string) => (
      <FrontImageStyled
        width={'8vw'}
        height={'8vw'}
        src={url}
        alt={i18n.t('column_titles:front_image')}
      />
    ),
  },
  {
    dataIndex: 'image_url_2',
    title: i18n.t('column_titles:back_image'),
    render: (url: string) =>
      url ? (
        <FrontImageStyled
          width={'8vw'}
          height={'8vw'}
          src={url}
          alt={i18n.t('column_titles:back_image')}
        />
      ) : (
        '-'
      ),
  },
  {
    dataIndex: 'created_at',
    title: i18n.t('column_titles:created_at'),
    columnType: 'date',
  },
  {
    dataIndex: 'fabrics_count',
    title: i18n.t('column_titles:number_of_results'),
    columnType: 'number',
  },
  {
    dataIndex: 'is_viewed',
    title: i18n.t('column_titles:viewed'),
    columnType: 'boolean',
  },
  {
    dataIndex: 'id',
    title: i18n.t('column_titles:action'),
    render: (imageId: string, row: ImageSearchListResponse) =>
      row.fabrics_count > 0 ? (
        <Link to={`/search/image/${imageId}`}>
          {i18n.t('column_titles:view')}
        </Link>
      ) : (
        '-'
      ),
  },
];
