import { Collapse, Drawer } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FilterButtonsContainer } from '../../../elements/StyledUI';
import {
  ApplyFilterButton,
  ResetFilterButton,
} from '../../../elements/buttons/StyledButtons';
import { dayjs } from '../../../plugins/dayjs';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import { RootAuth, Setter } from '../../../utils/types/Types';
import {
  BackendDateFilter,
  BackendFilterProps,
  BackendGenericFilter,
  BackendNumberFilter,
  FabricFilterDrawerProps,
  filterFieldProps,
  FilterValues,
  ObjectFilterItem,
  TransformedFilterProps,
} from '../FilterTypes';
import { CertFilter } from '../filterTemplate/CertFilter';
import { CheckboxFilter } from '../filterTemplate/CheckboxFilter';
import { DateRangeFilter } from '../filterTemplate/DateRangeFilter';
import { NumberRangeFilter } from '../filterTemplate/NumberRangeFilter';
import { createTransformedFields } from './transformFields';

export function FabricFilterDrawer({
  filterOpen,
  closeFilter,
  setFilterableColumns,
  filterableFields,
}: FabricFilterDrawerProps) {
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  // This is our reference for original filter data and is not updated
  const [initalFilterState, setInitialFilterState] =
    useState<BackendFilterProps>(createTransformedFields(filterableFields));

  // This is our update tracking for filter values that gets updated
  const [filterState, setFilterState] = useState<BackendFilterProps>(
    createTransformedFields(filterableFields)
  );

  useEffect(() => {
    setInitialFilterState(createTransformedFields(filterableFields));
    setFilterState(createTransformedFields(filterableFields));
  }, [filterableFields]);

  // Type guard for dayjs
  const isDayjs = (value: unknown): value is dayjs.Dayjs => {
    return dayjs.isDayjs(value);
  };

  // Transform FilterValues to the desired format
  const applyFilters = () => {
    const transformed: TransformedFilterProps = {};

    for (const key in filterState) {
      if (!(key in filterableFields)) {
        continue; // Skip if key is not in filterableFields
      }

      // Get the filter values for the current key
      const filter = filterState[key];
      // Get the original filter values from the backend for the current key
      const originalFilter = filterableFields[key];

      // Check if filter.values is an array
      if (Array.isArray(filter.values)) {
        if (filter.values.length > 0) {
          // Non-empty array, check for differences
          switch (filter.filter_type) {
            case 'number':
              if (
                filter.values[0] !==
                  (originalFilter as BackendNumberFilter).min ||
                filter.values[1] !==
                  (originalFilter as BackendNumberFilter).max ||
                filter.has_null !== originalFilter.has_null
              ) {
                transformed[key] = {
                  min: Number(filter.values[0]),
                  max: Number(filter.values[1]),
                  include_null: filter.has_null,
                };
              }
              break;

            case 'date':
              if (
                filter.values[0] !==
                  (originalFilter as BackendDateFilter).min ||
                filter.values[1] !==
                  (originalFilter as BackendDateFilter).max ||
                filter.has_null !== originalFilter.has_null
              ) {
                transformed[key] = {
                  min: String(
                    isDayjs(filter.values[0])
                      ? filter.values[0].format('YYYY-MM-DD')
                      : filter.values[0]
                  ),
                  max: String(
                    isDayjs(filter.values[1])
                      ? filter.values[1].format('YYYY-MM-DD')
                      : filter.values[1]
                  ),
                  include_null: filter.has_null,
                };
              }
              break;

            default:
              if (
                filter.values.length !==
                  (originalFilter as BackendGenericFilter).values.length ||
                filter.has_null !== originalFilter.has_null
              ) {
                transformed[key] = {
                  value: filter.values as string[],
                  include_null: filter.has_null,
                };
              }
              break;
          }
        } else {
          // Handle the case where filter.values is an empty array
          transformed[key] = {
            value: [],
            include_null: filter.has_null,
          };
        }
      }
    }

    setFilterableColumns(transformed);
    closeFilter(); // Close the drawer
  };

  const resetFilters = () => {
    setFilterState(initalFilterState); // Reset filterState to the initial state
  };

  const groupColumnsByType = (
    columns: BackendFilterProps
  ): Record<
    string,
    {
      key: string;
      filter:
        | BackendNumberFilter
        | BackendDateFilter
        | BackendGenericFilter
        | filterFieldProps;
    }[]
  > => {
    return Object.entries(columns).reduce(
      (acc, [key, column]) => {
        const group = column.grouping; // Extract grouping from the column
        if (!acc[group]) {
          acc[group] = []; // Initialize group as an array
        }
        acc[group].push({ key, filter: column }); // Push the key and filter as separate properties
        return acc;
      },
      {} as Record<
        string,
        {
          key: string;
          filter:
            | BackendNumberFilter
            | BackendDateFilter
            | BackendGenericFilter
            | filterFieldProps;
        }[]
      >
    );
  };

  const orderColumnsByKeys = (
    columns: {
      key: string;
      filter:
        | BackendNumberFilter
        | BackendDateFilter
        | BackendGenericFilter
        | filterFieldProps;
    }[]
  ) => {
    const filterableFieldsKeys = Object.keys(filterableFields);
    const groupedFilterableFields = returnOrderedFields(filterableFieldsKeys);
    // Step 1: Extract the keys from `groupedFilterableFields`
    const orderedKeys = groupedFilterableFields.map((field) => field.name);

    // Step 2: Sort the `columns` array based on the order in `orderedKeys`
    const orderedColumns = columns.sort((a, b) => {
      const indexA = orderedKeys.indexOf(a.key);
      const indexB = orderedKeys.indexOf(b.key);

      // If both keys are found, sort by their order; otherwise, keep their original order
      if (indexA > -1 && indexB > -1) {
        return indexA - indexB;
      } else if (indexA > -1) {
        return -1; // `a` comes before `b`
      } else if (indexB > -1) {
        return 1; // `b` comes before `a`
      } else {
        return 0; // Both keys are not found, retain original order
      }
    });

    return orderedColumns;
  };

  const filterItems = () => {
    const items: {
      label: string;
      key: number;
      children?: React.ReactNode;
      collapsible?: 'disabled'; // Optional collapsible prop
      showArrow?: boolean; // Optional showArrow prop
    }[] = [];
    let index = 0; // Initialize a counter to use as the key

    // Group the columns by their type
    const groupedColumns = groupColumnsByType(filterableFields);

    // Iterate over the grouped columns
    for (const [groupName, columns] of Object.entries(groupedColumns)) {
      const orderedColumns = orderColumnsByKeys(columns);
      // Add a grouping header (label) for each group
      items.push({
        label: i18n.t(`headers:${groupName}`),
        key: index,
        collapsible: 'disabled',
        showArrow: false,
      });

      index++; // Increment the index for the group label

      // Iterate through each column within the group
      orderedColumns.forEach(({ key, filter }) => {
        const obj: ObjectFilterItem = {
          label: parseFabricTitles(key, measurementUnit),
          key: index,
          children: null,
        };

        if (
          filter.filter_type === 'string' &&
          initalFilterState[key]?.values &&
          initalFilterState[key].values.length > 0
        ) {
          obj.children = (
            <CheckboxFilter
              choices={initalFilterState[key]?.values as string[]}
              field={key}
              isTranslatable={true}
              currentFilterValues={filterState}
              setCurrentFilterValues={
                setFilterState as Setter<FilterValues | BackendFilterProps>
              }
            />
          );
        } else if (
          key === 'certifications' &&
          initalFilterState[key]?.values &&
          initalFilterState[key].values.length > 0
        ) {
          obj.children = (
            <CertFilter
              choices={initalFilterState[key].values as string[]}
              field={key}
              currentFilterValues={filterState}
              setCurrentFilterValues={
                setFilterState as Setter<FilterValues | BackendFilterProps>
              }
            />
          );
        } else if (
          filter.filter_type === 'number' &&
          initalFilterState[key]?.values &&
          initalFilterState[key].values.length > 0
        ) {
          obj.children = (
            <NumberRangeFilter
              range={initalFilterState[key].values as [number, number]}
              field={key}
              currentFilterValues={filterState}
              setCurrentFilterValues={
                setFilterState as Setter<FilterValues | BackendFilterProps>
              }
            />
          );
        } else if (
          filter.filter_type === 'date' &&
          initalFilterState[key]?.values &&
          initalFilterState[key].values.length > 0
        ) {
          obj.children = (
            <DateRangeFilter
              range={initalFilterState[key].values.map((date) => dayjs(date))} // Convert each date string to Dayjs
              field={key}
              currentFilterValues={filterState}
              setCurrentFilterValues={
                setFilterState as Setter<FilterValues | BackendFilterProps>
              }
            />
          );
        }

        items.push(obj); // Add the column filter to the items
        index++; // Increment the index for each filter
      });
    }

    return items;
  };

  return (
    <Drawer
      open={filterOpen}
      placement="left"
      onClose={closeFilter}
      styles={{
        body: {
          padding: 0,
        },
      }}
      title={i18n.t('buttons:filter')}
    >
      <FilterButtonsContainer>
        <ResetFilterButton danger onClick={resetFilters}>
          {i18n.t('buttons:reset_filters')}
        </ResetFilterButton>
        <ApplyFilterButton type="primary" onClick={applyFilters}>
          {i18n.t('buttons:apply')}
        </ApplyFilterButton>
      </FilterButtonsContainer>
      <Collapse items={filterItems()} bordered={false} />
    </Drawer>
  );
}
