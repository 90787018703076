import i18n from 'i18next';
import {
  getLanguageLocalStorage,
  languageChoices,
} from '../utils/multiLanguage/antdLocale';

const languages = Object.keys(languageChoices) as string[];
const namespaces = [
  'common',
  'buttons',
  'column_titles',
  'language_options',
  'headers',
  'fabric_fields',
  'long_messages',
  'error_messages',
  'page_titles',
  'page_subtitles',
  'fabric_type_values',
  'lace_type_values',
  'stretch_values',
  'wash_care_values',
  'look_values',
  'special_yarn_values',
  'sustainability_values',
  'hand_feel_values',
  'lace_structure_values',
  'pattern_design_values',
  'season_values',
  'availability_values',
  'aesthetic_finish_values',
  'edge_finish_values',
  'measurement_unit_values',
  'construction_values',
  'composition_values',
  'status_values',
  'certification_status_values',
  'certification_type_values',
  'account_types',
  'hanger_status_values',
]; // Namespaces to be loaded, should follow the sheet names in the Platform Translation xlsx file

// Define the type for the resources
interface Resource {
  [language: string]: {
    [namespace: string]: {
      [key: string]: string;
    };
  };
}

const resources: Resource = languages.reduce((acc, lng) => {
  acc[lng] = namespaces.reduce(
    (nsAcc, ns) => {
      nsAcc[ns] = require(`../../public/locales/${lng}/${ns}.json`); // Adjust the path as necessary
      return nsAcc;
    },
    {} as Record<string, { [key: string]: string }>
  );
  return acc;
}, {} as Resource);

i18n.init({
  lng: getLanguageLocalStorage(), // Default language based on localStorage
  fallbackLng: {
    // Fallback language if the language is not supported or the translation is missing
    zh_HK: ['zh_CN'],
    zh_CN: ['zh_HK'],
    default: ['en_US'],
  },
  supportedLngs: languages,
  resources: resources,
  defaultNS: namespaces[0],
  fallbackNS: namespaces,
});

export default i18n;
