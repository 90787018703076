import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'i18next';

import { api } from '../api/api';
import { hideConfigModalProp, sharedUsersProp } from './ProjectTypes';

/**
 * Remove a user from a shared project
 */
export async function removeSharedProject(
  projectId: string,
  sharedUsers: sharedUsersProp,
  idToRemove: string,
  hideConfirmationModal: hideConfigModalProp
): Promise<sharedUsersProp> {
  const sharedUsersUpdated = sharedUsers.reduce(
    (accumulator, currentValue) => {
      if (currentValue.key !== idToRemove) {
        accumulator.id.push(currentValue.key);
        accumulator.users.push(currentValue);
      }
      return accumulator;
    },
    { id: [] as string[], users: [] as sharedUsersProp }
  );

  const data = { shared_with_user_ids: sharedUsersUpdated.id };

  try {
    await api({
      method: 'PUT',
      url: `v1/project/${projectId}/share`,
      body: data,
      headers: {
        'content-type': 'application/json',
      },
    });
    hideConfirmationModal();
    message.success(
      i18n.t('long_messages:message_project_removed_user_success')
    );
    return sharedUsersUpdated.users;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
