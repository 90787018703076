import { message } from 'antd';
import i18n from 'i18next';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Remove fabrics from a Collection via their ids.
 */
export async function deleteFabricsFromCollection(
  collectionId: string,
  removeFabrics: string[]
) {
  const data = { ids: removeFabrics };
  try {
    return await api({
      method: 'DELETE',
      url: `v1/collection/${collectionId}/fabric`,
      body: data,
    }).then((res: MsgProp) => {
      message.success(
        i18n.t('long_messages:message_collection_removed_fabric_success')
      );
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
