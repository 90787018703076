import { message } from 'antd';
import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

/**
 * Edit the status of selected hanger requests.
 */
export const editHangerRequest = async (
  fabricIds: string[],
  status: number
): Promise<boolean> => {
  const data = {
    hanger_requests_ids: fabricIds,
    status: status,
  };
  try {
    await api({
      method: 'PUT',
      url: 'v1/hanger_request',
      body: data,
    });
    message.success(i18n.t('long_messages:update_hanger_success_message'));
    return true;
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
};
