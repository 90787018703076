import { message } from 'antd';
import { isAxiosError } from 'axios';

import { api } from '../api/api';
import { sharedUsersProp } from './ProjectTypes';
/**
 * Gets the list of users that the project is shared with
 */
export async function getSharedUsers(
  projectId: string
): Promise<sharedUsersProp> {
  try {
    const response = await api({
      method: 'GET',
      url: `v1/project/${projectId}/shared_with_users`,
    });
    const sharedUsers = Object.entries(response).map(([key, value]) => ({
      key,
      value,
    })) as sharedUsersProp;
    return sharedUsers;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
