import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { containedFabricProjectsProps } from '../../../utils/backendRequests/project/ProjectTypes';
import { addProjectFabrics } from '../../../utils/backendRequests/project/addProjectFabrics';
import { copyCollectionFabricsToProject } from '../../../utils/backendRequests/project/copyCollectionFabricsToProject';
import { copyProjectToExistingProject } from '../../../utils/backendRequests/project/copyProjectToExistingProject';
import { getContainingFabricsProjects } from '../../../utils/backendRequests/project/getContainingFabricsProjects';
import { getProjects } from '../../../utils/backendRequests/project/getProjects';
import { Modal } from '../../Modal';
import { ProjectData } from '../../table/TableTypes';
import { CreateProjectModal } from './CreateProjectModal';
import { ProjectList } from './ProjectList';
import { AddProject, AddToProjectModalProps } from './ProjectModalTypes';

export function AddToProjectModal({
  modal,
  setModal,
  fabricIds = [],
  collectionId = null,
  srcProjectId = null,
  getNewProjects,
}: AddToProjectModalProps) {
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [ownedProjects, setOwnedProjects] = useState<ProjectData[]>([]);
  const [newProjectModal, setNewProjectModal] = useState({ visible: false });
  const [containedFabricProjects, setContainedFabricProjects] =
    useState<containedFabricProjectsProps>();

  const getProjectList = async () => {
    const data = await getProjects();
    setProjects(data);
  };

  const getcontainedFabricProjects = async () => {
    if (fabricIds.length !== 0) {
      const containedProjects = await getContainingFabricsProjects({
        fabricIds: fabricIds,
      });
      setContainedFabricProjects(containedProjects);
    } else if (srcProjectId !== null) {
      const containedProjects = await getContainingFabricsProjects({
        fabricSourceProjectId: srcProjectId,
      });
      setContainedFabricProjects(containedProjects);
    }
  };

  useEffect(() => {
    if (modal.visible) {
      getProjectList();
      getcontainedFabricProjects();
    }
  }, [modal.visible]);

  /**
   * Filter out projects that do not belong to the user
   * to prevent unauthorized addition of fabrics.
   */
  useEffect(() => {
    if (projects && projects.length > 0) {
      const userOwnedProjects = projects.filter(
        (project: AddProject) => project.is_owner
      );
      setOwnedProjects(userOwnedProjects);
    }
  }, [projects]);

  // Close modal
  const handleCancel = () => {
    setModal({
      visible: false,
    });
  };

  // Open create new project modal to create project and save selected fabrics to it. Also closes the AddToProjectModal
  const createNewProject = () => {
    setNewProjectModal({
      visible: true,
    });
    setModal({
      visible: false,
    });
  };

  // Saves fabrics to selected existing project
  const handleSaveToExisting = async (project: AddProject) => {
    /**
     * Either:
     * - Adds all the fabrics in a collection to an existing project,
     * - Adds all given fabrics to an existing project.
     */

    if (collectionId !== null) {
      copyCollectionFabricsToProject(project.id, collectionId).then(() => {
        setModal({
          visible: false,
        });
      });
    } else if (srcProjectId !== null) {
      copyProjectToExistingProject(srcProjectId, project.id).then(() => {
        setModal({
          visible: false,
        });
      });
    } else {
      addProjectFabrics(project.id, fabricIds).then(() => {
        if (getNewProjects) {
          getNewProjects();
        }
        setModal({
          visible: false,
        });
      });
    }
  };

  return (
    <>
      <Modal
        title={i18n.t('headers:add_to_project')}
        open={modal.visible}
        okText={i18n.t('headers:new_project')}
        onOk={createNewProject}
        onCancel={handleCancel}
      >
        <ProjectList
          handleSave={handleSaveToExisting}
          projects={ownedProjects}
          containedFabricProjects={
            containedFabricProjects?.project_ids as string[]
          }
          srcProjectId={srcProjectId}
        />
      </Modal>
      <CreateProjectModal
        modal={newProjectModal}
        setModal={setNewProjectModal}
        setParentModal={setModal}
        fabricIds={fabricIds}
        collectionId={collectionId}
        srcProjectId={srcProjectId}
        getProjectList={getProjectList}
      />
    </>
  );
}
