import { Button } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { SharedDesignerBrandsObject } from '../../../utils/backendRequests/collection/CollectionTypes';
import { getSharedDesignerBrands } from '../../../utils/backendRequests/collection/getSharedDesignerBrands';
import { shareCollection } from '../../../utils/backendRequests/collection/shareCollection';
import { Modal } from '../../Modal';
import {
  KeyValueDesignerBrandObject,
  ShareCollectionProps,
} from '../../table/collectionList/CollectionListTypes';
import { SharedDesignerBrands } from '../../table/collectionList/SharedDesignerBrands';

export function ShareCollectionModalWithLink({
  collectionId,
  approvedDesignerBrands,
  getCollectionsList,
}: ShareCollectionProps) {
  const [isModalOpen, setIsModalOpen] = useState<{ visible: boolean }>({
    visible: false,
  });
  const [sharedDesignerBrands, setSharedDesignerBrands] = useState<
    KeyValueDesignerBrandObject[]
  >([]);
  const [selectedSharedDesigners, setSelectedSharedDesigners] = useState<
    KeyValueDesignerBrandObject[]
  >([]);

  // Gets the list of designer brands that the collection is shared with
  const _getSharedDesignerBrands = async () => {
    const res: SharedDesignerBrandsObject =
      await getSharedDesignerBrands(collectionId);
    if (res) {
      const arraySharedDesignerBrands: KeyValueDesignerBrandObject[] =
        Object.entries(res).map(([key, value]) => {
          return { key, value };
        });
      setSharedDesignerBrands(arraySharedDesignerBrands);
    }
  };

  useEffect(() => {
    if (isModalOpen.visible) _getSharedDesignerBrands(); // Only make API call when the modal is opened
  }, [collectionId, isModalOpen.visible]);

  const handleCancel = () => {
    setIsModalOpen({ visible: false });
    setSelectedSharedDesigners([]);
  };

  const handleOk = async () => {
    // Find the maximum key value from selectedSharedDesigners
    const lastKeyValue = selectedSharedDesigners.reduce((max, item) => {
      const currentKey = parseInt(item.key, 10);
      return currentKey > max ? currentKey : max;
    }, -1); // Start with -1 to handle the case when selectedSharedDesigners is empty

    const transformedSharedBrands: KeyValueDesignerBrandObject[] =
      sharedDesignerBrands.map((item, index) => ({
        key: String(lastKeyValue + index + 1), // Continue from the last key value
        value: item.key,
        children: item.value,
      }));

    // Merge selected and previously shared with designer brands
    const updatedSharedDesignerBrands = transformedSharedBrands.concat(
      selectedSharedDesigners
    );

    // Convert designer brands objects to their Ids for backend API call
    const selectedSharedDesignerBradsIds = updatedSharedDesignerBrands.map(
      (designerBrand: KeyValueDesignerBrandObject) => designerBrand.value
    );
    await shareCollection(collectionId, selectedSharedDesignerBradsIds);
    // Updates list of shared designer brands after API call is completed
    getCollectionsList();
    // Clears the list of selected designer brands to prevent resubmission of same designer brands
    setSelectedSharedDesigners([]);
    await _getSharedDesignerBrands();
  };

  const showModal = () => {
    setIsModalOpen({ visible: true });
  };

  return (
    <>
      <a onClick={showModal} role="button">
        {i18n.t('buttons:share')}
      </a>
      <Modal
        open={isModalOpen.visible}
        width={1000}
        okText={i18n.t('buttons:share')}
        onCancel={handleCancel}
        destroyOnClose
        footer={
          <>
            <Button danger onClick={handleCancel}>
              {i18n.t('buttons:cancel')}
            </Button>
          </>
        }
      >
        <SharedDesignerBrands
          sharedDesignerBrands={sharedDesignerBrands}
          collectionId={collectionId}
          setSharedDesignerBrands={setSharedDesignerBrands}
          handleOk={handleOk}
          approvedDesignerBrands={approvedDesignerBrands}
          setSelectedSharedDesigners={setSelectedSharedDesigners}
          selectedSharedDesigners={selectedSharedDesigners}
        />
      </Modal>
    </>
  );
}
