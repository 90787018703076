import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import { TabTitle } from '../../components/TabTitle';
import { ExportSelectedRowsButton } from '../../components/fabric/ExportSelectedRowsButton';
import {
  BackendFilterProps,
  TransformedFilterProps,
} from '../../components/filter/FilterTypes';
import { FabricFilterDrawer } from '../../components/filter/fabric/FabricFilterDrawer';
import { FabricSwitchToggle } from '../../components/gallery/FabricSwitchToggle';
import { GalleryView } from '../../components/gallery/GalleryView';
import { SelectFabricColumns } from '../../components/modal/SelectFabricColumns';
import { SelectMobileFabricColumns } from '../../components/modal/SelectMobileFabricColumns';
import { CreateFabricsHangerRequest } from '../../components/modal/hangers/CreateFabricsHangerRequest';
import { intialDataProp } from '../../components/search/SearchTypes';
import { CompareButton } from '../../components/table/CompareButton';
import { FabricTableGeneric } from '../../components/table/FabricTableGeneric';
import { defaultPageSize } from '../../components/table/TableGeneric';
import { columnFields } from '../../components/table/TableTypes';
import { getSelectedColumns } from '../../components/table/fabricTable/ColumnsDesktop';
import { getSelectedMobileColumns } from '../../components/table/fabricTable/ColumnsMobile';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { Color } from '../../elements/base/Color';
import {
  ButtonBasic,
  DropdownButton,
  SelectFabricsButton,
} from '../../elements/buttons/StyledButtons';
import { ToggleButtonsContainer } from '../../elements/fabrics/fabricStyledElement';
import { MainLayout } from '../../layouts/Main';
import { routesNamedDict } from '../../routes';
import { deleteFabricsFromProjects } from '../../utils/backendRequests/project/deleteFabrics';
import { getFabrics } from '../../utils/backendRequests/project/getFabrics';
import { fabricPropertyArray } from '../../utils/fabrics/parseFabricFields';
import { getTranslatedValuesAndUpdateData } from '../../utils/fabrics/parseFabricValues';
import { record, RootAuth, RootState } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function ProjectDetailsPage({ title }: TitleProp) {
  const { projectId } = useParams(); // Project name received from the router
  const [projectName, setProjectName] = useState<string>('');
  const [data, setData] = useState<record[]>([]);
  const [translatedData, setTranslatedData] = useState<record[]>([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false); // Track select/deselect state
  const [fabricViewToggle, setFabricViewToggle] = useState<boolean>(() => {
    // Check if the value is already stored in localStorage
    const savedState = localStorage.getItem('fabricViewToggle');
    return savedState ? JSON.parse(savedState) : false; // Default to false if not found
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [columnModal, setColumnModal] = useState({ visible: false });
  const [requestHangerModal, setRequestHangerModal] = useState({
    visible: false,
  });
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  // Represents the range of filterable fields from the backend
  const [filterableFields, setFilterableFields] = useState<BackendFilterProps>(
    {}
  );
  // Represents the selected filter values to be sent to the backend
  const [filterableColumns, setFilterableColumns] =
    useState<TransformedFilterProps>({});

  // Pagination and Sorter state
  const [tableState, setTableState] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
    sortField: '',
    sortOrder: '',
  });
  const [sortableColumnNames, setSortableColumnNames] = useState<string[]>([]);

  const navigate = useNavigate();
  useEffect(() => {
    // Redirect if role is not 'designer'
    if (role !== 'designer') {
      navigate('/' + routesNamedDict.NOT_FOUND);
    }
  }, [role, navigate]);

  // columns to be initialized based on current selection or local storage
  const [columns, setColumns] = useState<columnFields[]>([]);
  const [selectableColumnNames, setSelectableColumnNames] = useState<string[]>(
    []
  );
  // Ids of selected fabrics according to the filtered selection
  const selectedIds = useMemo(() => {
    if (data.length === 0) {
      return [];
    } else {
      return selectedKeys
        .filter((rowKey) => data[(rowKey as number) - 1] !== undefined)
        .map((rowKey) => data[(rowKey as number) - 1]['id']);
    }
  }, [data, selectedKeys, fabricViewToggle]);

  const toggleHangerRequestModal = () => {
    setRequestHangerModal({ visible: false });
  };

  useEffect(() => {
    if (isMobileFlag) {
      setColumns(
        getSelectedMobileColumns(
          'project',
          measurementUnit,
          selectableColumnNames,
          role,
          account
        )
      );
    } else {
      setColumns(
        getSelectedColumns(
          'project',
          measurementUnit,
          role,
          selectableColumnNames,
          account
        )
      );
    }
  }, [isMobileFlag, measurementUnit, selectableColumnNames]);

  async function _getFabricsFromProject() {
    const res = await getFabrics(
      projectId as string,
      tableState,
      filterableColumns
    );
    if (res) {
      setProjectName(res.name);
      setData(res.fabrics);
      setIsOwner(res.is_owner);
      setTableState((prevState) => ({
        ...prevState,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
      if (Object.keys(filterableFields).length === 0) {
        setFilterableFields(res.filterable_columns);
      }
    }
  }

  async function _getFilteredFabricsFromProject() {
    setTableState((prevState) => ({
      ...prevState,
      current: 1,
    }));
    const res = await getFabrics(
      projectId as string,
      tableState,
      filterableColumns
    );
    if (res) {
      setData(res.fabrics);
      setIsOwner(res.is_owner);
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
    }
  }

  // Load data on pagination or sorter change
  useEffect(() => {
    if (data.length > 0) _getFabricsFromProject();
  }, [
    tableState.current,
    tableState.pageSize,
    tableState.sortField,
    tableState.sortOrder,
  ]);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      data,
      fabricPropertyArray
    ) as intialDataProp;
    setTranslatedData(translationData);
  }, [data]);

  // Get Filtered data when selected filters values change
  useEffect(() => {
    // Check if data has items and filterableFields is not an empty object
    // filterableFields represents the range of filterable fields from the backend
    if (data.length > 0 && Object.keys(filterableFields).length > 0) {
      _getFilteredFabricsFromProject();
    }
  }, [filterableColumns]);

  // Resets selected keys when filter updates data
  useEffect(() => {
    setSelectedKeys([]);
  }, [data]);

  // Get data on page load or resets data when all data filtered out
  useEffect(() => {
    if (data.length === 0) {
      _getFabricsFromProject();
    }
  }, [filterableColumns, projectId]);

  const handleConfirm = async () => {
    const deleteFabricsResult = await deleteFabricsFromProjects(
      projectId as string,
      selectedIds
    );
    if (deleteFabricsResult) {
      setSelectedKeys([]);
      _getFabricsFromProject();
      setModalVisible(false); // Close the modal after deletion
    }
  };

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: onSelectChange as (
      selectedRowKeys: React.Key[],
      selectedRows: record[]
    ) => void,
  };

  const hasSelected = selectedKeys.length > 0;

  const handleToggleChange = (checked: boolean) => {
    setFabricViewToggle(checked);
    // Store the toggle value in localStorage
    localStorage.setItem('fabricViewToggle', JSON.stringify(checked));
  };

  useEffect(() => {
    // Update isSelectAll based on whether all items are selected
    const isAllSelected = selectedKeys.length === translatedData.length;
    setIsSelectAll(isAllSelected);
  }, [fabricViewToggle, selectedKeys, translatedData.length]);

  // Function to handle "Select All" / "Deselect All" toggle
  const handleSelectAllToggle = () => {
    if (isSelectAll) {
      // Deselect all
      setSelectedKeys([]);
    } else {
      // Select all
      // +1 to align with the index from the Ant Design table, which starts from 1
      setSelectedKeys(translatedData.map((_, index) => index + 1));
    }
    setIsSelectAll(!isSelectAll); // Toggle the button state
  };

  const items = [
    {
      key: 'filter',
      label: (
        <DropdownButton
          disabled={data.length === 0}
          onClick={() => setFilterOpen(true)}
        >
          {i18n.t('buttons:filter')}
        </DropdownButton>
      ),
    },
    {
      key: 'compare',
      label: (
        <CompareButton
          filteredData={data}
          selectedRowKeys={selectedKeys}
          isMobile={true}
        />
      ),
    },
    {
      key: 'select-columns',
      label: (
        <DropdownButton
          disabled={data.length === 0 || fabricViewToggle}
          onClick={() => setColumnModal({ visible: true })}
        >
          {i18n.t('buttons:select_columns')}
        </DropdownButton>
      ),
    },
    {
      key: 'export',
      label: (
        <ExportSelectedRowsButton
          disabled={!hasSelected}
          data={data}
          selectedRowKeys={selectedKeys}
          exportName={i18n.t('buttons:all_fabrics')}
        />
      ),
    },
    // Conditionally include the "remove" button if isOwner is true
    ...(isOwner
      ? [
          {
            key: 'remove',
            label: (
              <DropdownButton
                onClick={() => setModalVisible(true)}
                disabled={!hasSelected}
                danger
              >
                {i18n.t('buttons:remove')}
              </DropdownButton>
            ),
          },
        ]
      : []),
    // Conditionally include the request hanger button for designers only
    ...(role === 'designer'
      ? [
          {
            key: 'hanger',
            label: (
              <DropdownButton
                onClick={() => setRequestHangerModal({ visible: true })}
                disabled={data.length === 0 || selectedKeys.length === 0}
              >
                {i18n.t('buttons:request_hanger')}
              </DropdownButton>
            ),
          },
        ]
      : []),
  ];

  return (
    <MainLayout>
      <HeaderTitle
        title={`${i18n.t('page_titles:project_page')} "${projectName}"`}
      />
      <TabTitle title={title} id={projectId} />
      {isMobileFlag ? (
        <SelectMobileFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'project'}
          selectableColumnNames={selectableColumnNames}
        />
      ) : (
        <SelectFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'project'}
          selectableColumnNames={selectableColumnNames}
        />
      )}
      <CreateFabricsHangerRequest
        visible={requestHangerModal.visible}
        onCancel={toggleHangerRequestModal}
        fabricIds={selectedIds}
      />
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={isMobileFlag ? 8 : undefined}>
          {isMobileFlag ? (
            <Dropdown menu={{ items }}>
              <ButtonBasic>
                <Space>
                  {i18n.t('buttons:Options')}
                  <DownOutlined />
                </Space>
              </ButtonBasic>
            </Dropdown>
          ) : (
            <Space direction="horizontal" size="small">
              <CompareButton
                filteredData={translatedData}
                selectedRowKeys={selectedKeys}
              />
              <ButtonBasic
                onClick={() => setFilterOpen(true)}
                disabled={
                  data.length === 0 &&
                  Object.keys(filterableColumns).length === 0
                }
              >
                {i18n.t('buttons:filter')}
              </ButtonBasic>
              <ButtonBasic
                onClick={() => setColumnModal({ visible: true })}
                disabled={data.length === 0 || fabricViewToggle}
              >
                {i18n.t('buttons:select_columns')}
              </ButtonBasic>
              {fabricViewToggle && (
                <ButtonBasic
                  onClick={() => handleSelectAllToggle()}
                  disabled={translatedData.length === 0}
                >
                  {isSelectAll
                    ? i18n.t('headers:deselect_all')
                    : i18n.t('headers:select_all')}
                </ButtonBasic>
              )}
              <ButtonBasic
                onClick={() => setRequestHangerModal({ visible: true })}
                disabled={data.length === 0 || selectedKeys.length === 0}
              >
                {i18n.t('buttons:request_hanger')}
              </ButtonBasic>
              <ExportSelectedRowsButton
                disabled={!hasSelected}
                data={translatedData}
                selectedRowKeys={selectedKeys}
                exportName={projectName}
              />
              {isOwner && (
                <ButtonBasic
                  onClick={() => setModalVisible(true)}
                  disabled={!hasSelected}
                  danger
                >
                  {i18n.t('buttons:remove')}
                </ButtonBasic>
              )}
            </Space>
          )}
        </Col>
        <ToggleButtonsContainer>
          <Row align="middle" gutter={8}>
            {fabricViewToggle && (
              <Col>
                <SelectFabricsButton
                  onClick={() => handleSelectAllToggle()}
                  disabled={translatedData.length === 0}
                >
                  {isSelectAll
                    ? i18n.t('headers:deselect_all')
                    : i18n.t('headers:select_all')}
                </SelectFabricsButton>
              </Col>
            )}
            <Col>
              <FabricSwitchToggle
                fabricViewToggle={fabricViewToggle}
                handleToggleChange={handleToggleChange}
              />
            </Col>
          </Row>
        </ToggleButtonsContainer>
      </Row>
      <Modal
        title={i18n.t('headers:remove_fabrics')}
        open={modalVisible}
        onOk={handleConfirm}
        onCancel={() => setModalVisible(false)}
        okText={i18n.t('buttons:yes')}
        cancelText={i18n.t('buttons:no')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        <p>{i18n.t('long_messages:message_remove_fabrics_from_project')}</p>
      </Modal>
      <Row>
        <FabricFilterDrawer
          filterOpen={filterOpen}
          closeFilter={() => setFilterOpen(false)}
          setFilterableColumns={setFilterableColumns}
          filterableFields={filterableFields}
        />
        {fabricViewToggle ? (
          <GalleryView
            fabrics={translatedData}
            tableState={tableState}
            setTableState={setTableState}
            isMobile={isMobileFlag}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            pageType={'project'}
          />
        ) : (
          <FabricTableGeneric
            rowSelection={rowSelection}
            columns={columns}
            data={translatedData}
            tableState={tableState}
            setTableState={setTableState}
            isBackendPagination={true}
            sortableColumns={sortableColumnNames}
          />
        )}
      </Row>
    </MainLayout>
  );
}
