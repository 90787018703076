import { Card, Col, Layout, Row, Space } from 'antd';
import styled from 'styled-components';
import { Color } from './base/Color';

export const StyledRow = styled(Row)`
  width: 100%;
  justify-content: center;
  align-items: middle;
`;

export const NoAuthStyledRow = styled(Row)`
  width: 100%;
  align-items: middle;
  margin-left: 25px;
`;

// Header Phone Top Bar
// Styled Col components
export const MobileStyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const MobileLeftCol = styled(MobileStyledCol)`
  padding-left: 10px;
  width: 33.33%;
`;

export const MobileCenterCol = styled(MobileStyledCol)`
  text-align: center;
  width: 33.33%;
  justify-content: center;
`;

export const MobileRightCol = styled(MobileStyledCol)`
  padding-right: 20px;
  cursor: pointer;
  width: 33.33%;
  justify-content: flex-end;
`;

// Styled div components
export const MobileLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileHeaderContainer = styled.div`
  width: 100%;
  display: flex;
`;

// Desktop Header Styling
export const DesktopHeaderStyled = styled(Col)`
  display: flex;
  align-items: center;
`;

// No Auth Header Styling
export const NoAuthDesktopHeaderStyled = styled(Col)`
  display: flex;
  justify-content: flex-start; // Align content to the left horizontally
`;

export const DesktopSearchbarStyled = styled(Col)`
  text-align: center;
  align-content: center;
`;

interface StyledDropdownProps {
  span: number;
}

export const StyledDropDownCol = styled(Col)<StyledDropdownProps>`
  text-align: right;
  align-content: center;
`;

export const StyledDropdownDiv = styled.div`
  padding-right: 20px;
`;

interface StyledProfileDropdownProps {
  isAdmin: boolean;
  children: React.ReactNode;
}

export const StyledProfileDropdown = ({
  isAdmin,
  children,
}: StyledProfileDropdownProps) => {
  return (
    <StyledDropDownCol span={isAdmin ? 18 : 6}>
      <StyledDropdownDiv>{children}</StyledDropdownDiv>
    </StyledDropDownCol>
  );
};

// User Information Card Header
export const CardHeaderRow = styled(Row)<{ hidden: boolean }>`
  border-bottom: ${(hidden) => (hidden ? 'none' : `1px solid ${Color.G1}`)};
  padding-left: 16px;
  width: 100%;
`;

// Profile Dropdown
export const ProfileDropDownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ProfileDropDownName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
`;

export const UserInformationContainer = styled(Card)`
  border-radius: 20px;
  overflow: hidden;
`;

// Main and Admin Layout
export const LayoutContainer = styled(Layout)`
  height: 100%;
`;

export const MainLayoutWrapper = styled.div<{
  $isMobileFlag: boolean;
  $isSidebarCollapsed: boolean;
  $siderBarWidth: string;
}>`
  width: ${({ $isMobileFlag, $isSidebarCollapsed, $siderBarWidth }) =>
    $isMobileFlag || $isSidebarCollapsed
      ? '100%'
      : `calc(100% - ${$siderBarWidth})`};
  display: ${({ $isMobileFlag, $isSidebarCollapsed }) =>
    $isMobileFlag && !$isSidebarCollapsed ? 'none' : 'block'};
`;

// Attachments
export const StyledAttachmentContainer = styled(Space)<{
  $isMobileFlag: boolean;
}>`
  width: ${($isMobileFlag) => ($isMobileFlag ? '100%' : 'auto')};
  text-align: ${($isMobileFlag) => ($isMobileFlag ? 'center' : 'left')};
`;

// Fabric Filter Drawer
export const FilterButtonsContainer = styled.div`
  position: sticky;
  background: white;
  top: 0;
  z-index: 1000;
`;
