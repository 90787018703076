import i18n from 'i18next';
import { useState } from 'react';

import { moveFabrics } from '../../../utils/backendRequests/library/moveFabrics';
import { Modal } from '../../Modal';
import { CreateFabricLibraryModal } from '../CreateFabricLibraryModal';
import { LibraryList } from './LibraryList';
import { LibraryType, MoveToLibraryModalProps } from './LibraryModalTypes';

interface NewLibraryModalState {
  visible: boolean;
  onLibraryCreated?: (name: string) => void;
}

export function MoveToLibraryModal({
  modal,
  setModal,
  fabricIds = [],
  getFabrics,
  currentLibraryId,
  libraries,
  getLibraryList,
}: MoveToLibraryModalProps) {
  const [newLibraryModal, setNewLibraryModal] = useState<NewLibraryModalState>({
    visible: false,
  });
  /**
   * Filter out Libraries that do not belong to the user
   * to prevent unauthorized addition of fabrics.
   */
  const ownedLibraries = libraries.filter(
    (library: LibraryType) =>
      library.is_owner && library.id !== currentLibraryId
  );
  // Close modal
  const handleCancel = () => {
    setModal({
      visible: false,
    });
  };

  // Open create new library modal to create library and move selected fabrics
  // from existing library to it. Also closes the modal
  const createNewLibrary = () => {
    setNewLibraryModal({
      visible: true,
    });
    setModal({
      visible: false,
    });
  };

  // Moves fabrics to selected existing library
  const handleSaveToExisting = async (library: LibraryType) => {
    /**
     *
     * - Moves fabrics to an existing list of fabrics in an existing library.
     */
    const successFlag = await moveFabrics(library.id, fabricIds);

    if (successFlag) {
      setModal({
        visible: false,
      });
      getFabrics();
    }
  };

  return (
    <>
      <Modal
        title={i18n.t('buttons:move_to_library')}
        open={modal.visible}
        okText={i18n.t('headers:new_library')}
        onOk={createNewLibrary}
        onCancel={handleCancel}
      >
        <LibraryList
          handleSave={handleSaveToExisting}
          libraries={ownedLibraries}
        />
      </Modal>
      <CreateFabricLibraryModal
        modal={newLibraryModal}
        setModal={setNewLibraryModal}
        moveFabricsToNewLibrary={handleSaveToExisting}
        getLibraryList={getLibraryList}
      />
    </>
  );
}
