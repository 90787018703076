import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootAuth } from '../../../utils/types/Types';
import { TabTitle } from '../../TabTitle';
import { TableGeneric } from '../TableGeneric';
import { columnFields, SupplierListProps } from '../TableTypes';
import { SupplierColumns } from './SupplierColumns';

export function SuppliersList({
  data,
  title,
  editComment,
  removeSupplier,
}: SupplierListProps) {
  const [columns, setColumns] = useState<columnFields[]>([]);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  useEffect(() => {
    setColumns(
      SupplierColumns(account, editComment, removeSupplier) as columnFields[]
    );
  }, []);

  return (
    <>
      {data[0] && <TabTitle title={title} />}
      <TableGeneric columns={columns} data={data} isBackendPaginated={false} />
    </>
  );
}
