import { message } from 'antd';
import { api } from '../api/api';
import { SharedDesignerBrandsObject } from './CollectionTypes';

/**
 * Gets the map of designer brands that the collection is shared with
 */
export async function getSharedDesignerBrands(
  collectionId: string
): Promise<SharedDesignerBrandsObject> {
  try {
    return await api({
      method: 'GET',
      url: `v1/collection/${collectionId}/shared_with_designer_brands`,
    }).then((res: SharedDesignerBrandsObject) => {
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
